.navbar--eshop {
  .box-actions {
    margin-top: 0;

    .btn-plain {
      margin-left: 0;
      padding-top: rem(10);
      padding-bottom: rem(13);
      padding-left: rem(18);
      padding-right: rem(18);
      border-left: none;
      background-color: $white;
      border-radius: 24px;
      border: 1px solid transparent;
      @include render-for(compact-scr) {
        padding-left: rem(0);
        padding-right: rem(0); }
      &.empty {
        i {
          margin-right: rem(15); } }

      i {
        font-size: rem(16);
        margin-right: rem(15); }

      .icon-scale {
        position: relative;
        font-size: 1rem;
        @include render-for(compact-scr) {
          margin-right: 0 !important;
          & + span {
            display: none; } } }

      & + .btn-plain {
        margin-left: rem(10);
        @include render-for(compact-scr) {
          margin-left: rem(20); } } }

    .btn-only-icon {
      padding: 0;
      i {
        margin-right: 0; }
      .icon-dog {
        font-size: rem(28); }
      .icon-heart-fill {
        font-size: rem(20); } }

    .badge {
      pointer-events: none;
      position: absolute;
      right: -.7em;
      top: -.7em;
      border-radius: 50%;
      font-style: normal;
      padding: rem(2) rem(5);
      font-size: rem(10);
      border: 2px solid $white; } }

  .box-actions--compare-and-cart {
    align-self: center;
    margin-top: rem(-10);
    margin-bottom: rem(-13);
    margin-right: rem(-18);
    @include render-for(compact-scr) {
      margin-right: 0; }

    .active {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: rem(-12);
        left: rem(18);
        right: rem(18);
        border-bottom: 2px solid;
        @include theme-color(color);
        @include render-for(compact-scr) {
          bottom: rem(5);
          left: 0;
          right: 0; } } } }

  @include render-for(large-scr) {
    .box-actions--sticky--fixed {
      top: 20px;
      position: fixed;
      z-index: 99;
      .btn-plain {
        border-color: #ced4da; } } } }

.box-actions {
  display: flex;
  margin-top: rem(30);
  @include render-to($navbar-eshop-breakpoint-md - 1) {
    flex-direction: column;

    .btn {
      width: auto; } }

  .btn-sm {
    display: flex;
    align-items: center; }

  .btn-primary--ms {
    font-size: 0.88889rem; }

  .btn-plain {
    display: flex;
    align-items: center;
    padding-top: rem(3);
    padding-bottom: 0;
    font-size: rem(16);
    border-left: 1px solid $gray-500;
    padding-left: rem(30);
    margin-left: rem(30); }

  .btn-plain--homepage {
    @include render-to(md) {
      border-left: none;
      padding-left: rem(0);
      margin-left: rem(0);
      margin-top: rem(20);
      font-size: rem(20); }

    span {
      display: inline-block;
      vertical-align: super;
      line-height: rem(15); }

    i {
      line-height: 20px;
      color: $primary;
      font-size: rem(32);
      margin-right: rem(15); } } }

.box-actions--navbar-lg {
  display: none;
  @include render-to($navbar-breakpoint) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    .btn {
      margin-top: rem(10); } } }

.box-actions--navbar-full {
  display: none;
  @include render-up($navbar-breakpoint) {
    display: flex; } }
