.product-row {
  padding: rem(8) 0 rem(5) 1.8%;
  font-size: rem(15);
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lighten($gray-200, 2%);
  color: lighten($gray-600, 2%);
  @include render-to(lg) {
    padding-left: rem(20);
    padding-bottom: rem(10);
    flex-wrap: wrap; }

  [class*="product-row"] {
    flex: 1 0 auto; } }

.product-row__img {
  padding-right: 1.8%;
  min-width: rem(63);
  max-width: rem(63);

  img {
    max-width: 100%;
    max-height: rem(37); } }

.product-row__name {
  padding-right: 2.94%;
  flex: 1 0 auto;
  @include render-to(xs) {
    max-width: calc(100% - #{rem(100)}); }

  p {
    margin: 0;
    font-size: rem(15);
    color: $text-color;
    font-weight: 600; }

  a {
    color: inherit;
    &:hover {
      span {
        text-decoration: underline; } } }

  h5 {
    margin: 0;
    height: em(24, 16);
    font-weight: 300;
    font-size: rem(16);
    line-height: 1;
    position: relative;
    @include render-to(xs) {
      height: auto; }

    span {
      width: 100%;
      white-space: nowrap;
      @include all-abs;
      overflow: hidden;
      text-overflow: ellipsis;
      @include render-to(xs) {
        position: static;
        white-space: normal; } } } }

.product-row__amount {
  padding-right: 6.1%;
  box-sizing: content-box;
  min-width: rem(77);
  max-width: rem(77);
  @include render-to(xs) {
    box-sizing: border-box; }

  .form-control-wrapper {
    display: inline-block; }

  .form-control {
    padding: rem(6) rem(20) rem(7) rem(10);
    width: rem(77);
    text-align: center; } }

.product-row__stock {
  padding-right: 2.16%;
  box-sizing: content-box;
  min-width: rem(120);
  max-width: rem(120);
  @include render-to(lg) {
    margin-top: rem(10);
    padding-right: 0;
    width: 100%; }

  .in-stock {
    display: inline-block;
    color: inherit; } }

.product-row__shipped-from {
  padding-right: rem(5);
  min-width: rem(79);
  max-width: rem(79); }

.product-row__price-pc {
  padding-right: 1.7%;
  min-width: rem(95);
  max-width: rem(95);
  text-align: right; }

.product-row__discount {
  padding-right: rem(5);
  min-width: rem(40);
  max-width: rem(40); }

.product-row__price {
  min-width: rem(84);
  max-width: rem(84);
  font-size: rem(16);
  text-align: right;
  color: $text-color; }

.product-row__date {
  padding-right: rem(20);
  min-width: rem(94);
  max-width: rem(94); }

.product-row__company {
  min-width: rem(200);
  max-width: rem(200); }

.product-row__actions {
  padding-left: 2.5%;
  min-width: rem(25);
  max-width: rem(25);
  text-align: right;
  box-sizing: content-box;
  @include render-to(lg) {
    position: absolute;
    top: rem(20);
    right: rem(20); }
  @include render-to(xs) {
    top: rem(30); }

  a {
    text-decoration: none;
    color: inherit; }

  .icon {
    font-size: rem(12);
    color: inherit; }

  .remove {
    margin-right: rem(10);
    &:hover {
      color: $red; } } }

.product-row__stock,
.product-row__shipped-from,
.product-row__price-pc,
.product-row__discount,
.product-row__price {
  @include render-to(lg) {
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    &::before {
      content: attr(data-label) ":";
      padding-right: rem(20);
      width: rem(120);
      display: inline-block; } } }

.product-row__amount,
.product-row__date,
.product-row__company {
  @include render-to(xs) {
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    &::before {
      content: attr(data-label) ":";
      padding-right: rem(20);
      width: rem(120);
      display: inline-block; } } }

.product-row__amount,
.product-row__date {
  @include render-to(xs) {
    margin-top: rem(10); } }

.product-row__date,
.product-row__company {
  &::before {
    width: rem(60); } }

.product-row--header {
  [class*="product-row"] {
    font-size: rem(14);
    color: darken($gray-500, 5%); }

  .product-row__stock,
  .product-row__shipped-from,
  .product-row__price-pc,
  .product-row__discount,
  .product-row__price {
    @include render-to(lg) {
      display: none; } }

  .product-row__amount,
  .product-row__date,
  .product-row__company {
    @include render-to(xs) {
      display: none; } } }
