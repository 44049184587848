.block--lost-password {
  .separator {
    margin: 0 rem(50);
    @include render-to(md) {
      display: none; } }

  @include render-to(md) {
    .form-inline {
      flex-direction: column;
      align-items: baseline;

      input {
        width: 100%;
        display: inline-block; } }

    .form-group {
      margin-bottom: rem(10); }

    label {
      justify-content: left;
      min-width: rem(160);
      margin-bottom: rem(5); }

    .btn-primary {
      margin-bottom: rem(30); } } }
