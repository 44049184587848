@font-face {
	font-family: "icon-font";
	src: url('../fonts/icon-font/icon-font.woff2') format('woff2'),
		url('../fonts/icon-font/icon-font.woff') format('woff'),
		url('../fonts/icon-font/icon-font.ttf') format('truetype')
}

.icon:before {
	font-family: "icon-font";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-360:before {
	content: "\E001";
}

.icon-add-to-cart:before {
	content: "\E002";
}

.icon-arrow-down-2:before {
	content: "\E003";
}

.icon-arrow-down-bold:before {
	content: "\E004";
}

.icon-arrow-down:before {
	content: "\E005";
}

.icon-arrow-left:before {
	content: "\E006";
}

.icon-arrow-right:before {
	content: "\E007";
}

.icon-arrow-up-bold:before {
	content: "\E008";
}

.icon-arrow-up:before {
	content: "\E009";
}

.icon-bag:before {
	content: "\E00A";
}

.icon-briefcase:before {
	content: "\E00B";
}

.icon-bulb-full:before {
	content: "\E00C";
}

.icon-bulb:before {
	content: "\E00D";
}

.icon-calendar:before {
	content: "\E00E";
}

.icon-cart:before {
	content: "\E00F";
}

.icon-check:before {
	content: "\E010";
}

.icon-close-bold:before {
	content: "\E011";
}

.icon-close:before {
	content: "\E012";
}

.icon-computer:before {
	content: "\E013";
}

.icon-damaged-package:before {
	content: "\E014";
}

.icon-dart-bottom:before {
	content: "\E015";
}

.icon-dart-up:before {
	content: "\E016";
}

.icon-documents:before {
	content: "\E017";
}

.icon-dog:before {
	content: "\E018";
}

.icon-download:before {
	content: "\E019";
}

.icon-fax:before {
	content: "\E01A";
}

.icon-file-jpg:before {
	content: "\E01B";
}

.icon-file-pdf:before {
	content: "\E01C";
}

.icon-file-xls:before {
	content: "\E01D";
}

.icon-file-xml:before {
	content: "\E01E";
}

.icon-filter:before {
	content: "\E01F";
}

.icon-hamburger:before {
	content: "\E020";
}

.icon-hands:before {
	content: "\E021";
}

.icon-heart-fill:before {
	content: "\E022";
}

.icon-heart:before {
	content: "\E023";
}

.icon-home-2:before {
	content: "\E024";
}

.icon-home:before {
	content: "\E025";
}

.icon-info:before {
	content: "\E026";
}

.icon-mov:before {
	content: "\E027";
}

.icon-move:before {
	content: "\E028";
}

.icon-order-approve:before {
	content: "\E029";
}

.icon-percentage:before {
	content: "\E02A";
}

.icon-percents:before {
	content: "\E02B";
}

.icon-phone:before {
	content: "\E02C";
}

.icon-play-rounded:before {
	content: "\E02D";
}

.icon-plug:before {
	content: "\E02E";
}

.icon-price-label:before {
	content: "\E02F";
}

.icon-price-package:before {
	content: "\E030";
}

.icon-prices:before {
	content: "\E031";
}

.icon-rightArrow:before {
	content: "\E032";
}

.icon-scale-solid:before {
	content: "\E033";
}

.icon-scale:before {
	content: "\E034";
}

.icon-search:before {
	content: "\E035";
}

.icon-sol-element-empty:before {
	content: "\E036";
}

.icon-star:before {
	content: "\E037";
}

.icon-sun:before {
	content: "\E038";
}

.icon-triangle-right:before {
	content: "\E039";
}

.icon-upload:before {
	content: "\E03A";
}

.icon-user:before {
	content: "\E03B";
}

.icon-view-grid:before {
	content: "\E03C";
}

.icon-view-row:before {
	content: "\E03D";
}

.icon-youtube:before {
	content: "\E03E";
}

.icon-zip:before {
	content: "\E03F";
}





$icon-360: "\E001";

$icon-add-to-cart: "\E002";

$icon-arrow-down-2: "\E003";

$icon-arrow-down-bold: "\E004";

$icon-arrow-down: "\E005";

$icon-arrow-left: "\E006";

$icon-arrow-right: "\E007";

$icon-arrow-up-bold: "\E008";

$icon-arrow-up: "\E009";

$icon-bag: "\E00A";

$icon-briefcase: "\E00B";

$icon-bulb-full: "\E00C";

$icon-bulb: "\E00D";

$icon-calendar: "\E00E";

$icon-cart: "\E00F";

$icon-check: "\E010";

$icon-close-bold: "\E011";

$icon-close: "\E012";

$icon-computer: "\E013";

$icon-damaged-package: "\E014";

$icon-dart-bottom: "\E015";

$icon-dart-up: "\E016";

$icon-documents: "\E017";

$icon-dog: "\E018";

$icon-download: "\E019";

$icon-fax: "\E01A";

$icon-file-jpg: "\E01B";

$icon-file-pdf: "\E01C";

$icon-file-xls: "\E01D";

$icon-file-xml: "\E01E";

$icon-filter: "\E01F";

$icon-hamburger: "\E020";

$icon-hands: "\E021";

$icon-heart-fill: "\E022";

$icon-heart: "\E023";

$icon-home-2: "\E024";

$icon-home: "\E025";

$icon-info: "\E026";

$icon-mov: "\E027";

$icon-move: "\E028";

$icon-order-approve: "\E029";

$icon-percentage: "\E02A";

$icon-percents: "\E02B";

$icon-phone: "\E02C";

$icon-play-rounded: "\E02D";

$icon-plug: "\E02E";

$icon-price-label: "\E02F";

$icon-price-package: "\E030";

$icon-prices: "\E031";

$icon-rightArrow: "\E032";

$icon-scale-solid: "\E033";

$icon-scale: "\E034";

$icon-search: "\E035";

$icon-sol-element-empty: "\E036";

$icon-star: "\E037";

$icon-sun: "\E038";

$icon-triangle-right: "\E039";

$icon-upload: "\E03A";

$icon-user: "\E03B";

$icon-view-grid: "\E03C";

$icon-view-row: "\E03D";

$icon-youtube: "\E03E";

$icon-zip: "\E03F";

