.brand-billboard {
  background-color: $primary;
  margin-top: -1px;

  @include render-for(compact-scr) {
    margin-top: rem(20); }

  @include render-for(xs) {
    display: none; } }

.brand-billboard--white {
  background-color: $white; }

.brand-billboard--red {
  background-color: $brand-red; }

.brand-billboard--carmine {
  background-color: $brand-carmine; }

.brand-billboard--black {
  background-color: $brand-black; }

.brand-billboard--dark {
  background-color: $brand-dark; }

.brand-billboard--orange_white {
  background-color: $billboard-orange_white; }

.brand-billboard--vulcan {
  background-color: $billboard-vulcan; }

.brand-billboard--blue {
  background-color: $brand-blue; }

.brand-billboard--golden_dark {
  background-color: $brand-golden_dark; }

.brand-billboard--carrot_orange {
  background-color: $brand-carrot_orange; }

.brand-billboard--green_atlantis {
  background-color: $brand-green_atlantis; }

.brand-billboard--blue_cerulean {
  background-color: $brand-blue_cerulean; }

.brand-billboard--blue_endevour {
  background-color: $brand-blue_endevour; }
