//
// Icons
// -------------------------------

.icon {
  @include theme-color(color);
  font-size: 1em; }

.icon--text-big {
  float: left;
  font-size: rem(30);
  line-height: rem(30);
  margin-right: rem(15); }

.icon-red {
  color: $icon-red-color; }

.icon-green {
  color: $icon-green-color; }

.icon-orange {
  color: $icon-orange-color; }

.icon-black {
  color: $black; }

.icon-small {
  font-size: rem(30); }

.icon-medium {
  font-size: rem(40); }

.icon-large {
  font-size: rem(100); }
