.header {
  z-index: 11;

  .block__content {
    padding-top: 0;
    margin: 0 auto;
    z-index: 11; } }

.header--underline {
  border-bottom: 1px solid $gray-200; }

.header--eshop {
  @include render-for(compact-scr) {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    border-bottom: none; } }
