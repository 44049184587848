.box-search {
  display: flex;
  align-self: baseline;
  @include render-to(md) {
    flex-direction: column;
    max-width: none;
    position: relative;
    float: none;
    padding: rem(40);
    top: rem(10);

    .btn-lg {
      font-size: rem(24);
      padding: rem(20); } }

  .form-inline {
    position: relative;
    @include render-to(md) {
      margin-bottom: rem(20);
      margin-right: 0;

      .form-group, .form-control {
        width: 100%;
        margin-bottom: 0; } } }

  .form-group, .form-control {
    max-width: 100%; }

  input {
    padding-right: rem(35);
    @include render-to(md) {
      width: 100%;
      font-size: rem(24);
      padding: rem(15) rem(40); } }

  .search-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding: rem(7) rem(11);
    background-color: transparent;
    @include render-to(md) {
      font-size: rem(25); }
    @include render-to(xs) {
      width: auto; }

    i {
      line-height: rem(24);
      @include theme-color(color);
      @include render-to(sm) {
        font-size: rem(20); } } } }

.box-search--inline-form {
  position: relative;
  margin-left: rem(22);
  @include render-to($navbar-breakpoint) {
    margin-left: 0;
    flex: 1; }

  .form-inline {
    margin-bottom: 0;
    width: 100%; }
  @include render-to($navbar-eshop-breakpoint-md - 1) {
    top: initial;
    max-width: none;
    padding: 0;
    .form-group,
    .form-control {
      max-width: none;
      flex: auto; }
    .form-control {
      font-size: rem(15);
      width: 100%;
      padding: rem(8) rem(40) rem(8) rem(10); }
    .search-button {
      padding: rem(5) rem(10);
      i {
        font-size: rem(18); } } }

  .form-control {
    padding-top: rem(7);
    padding-bottom: rem(7); } }

.box-search--full-width {
  width: 100%; }

.box-search--with-suggestions {
  .box-search__suggestions {
    display: block; } }

.box-search__suggestions {
  display: none;
  position: absolute;
  padding: rem(20) rem(35);
  background-color: $white;
  top: calc(100% - 1px);
  left: 0;
  border: 1px solid $gray-400;
  z-index: 100;
  min-width: rem(360);
  @include render-to(xs) {
    min-width: rem(310); }

  a {
    color: $gray-600;
    display: flex;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      @include theme-color(color); } }

  img {
    max-width: rem(50);
    max-height: rem(50);
    margin-right: rem(15); }

  strong {
    font-weight: 400;
    color: $primary; } }

.box-search--homepage {
  .btn-primary {
    margin-left: 10px;
    @include render-to(md) {
      margin-top: 10px;
      margin-left: 0; }
    .icon {
      font-size: 13px;
      margin-right: 10px; } } }
