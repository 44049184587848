.user-row {
  padding: rem(15) 0;
  font-size: rem(15);
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-300;
  color: lighten($gray-600, 2%);
  @include render-to(lg) {
    padding-bottom: rem(10);
    flex-wrap: wrap; }

  [class*="user-row"] {
    flex: 1 0 auto;
    padding-right: rem(15);
    @include render-to(lg) {
      min-width: 100% !important;
      max-width: 100% !important;
      text-align: left;
      &::before {
        content: attr(data-label) ":";
        padding-right: rem(20);
        width: rem(170);
        display: inline-block; } }

    @include render-to(xs) {
      margin-bottom: rem(10);
      &::before {
        width: 100%; } } }

  &:hover {
    background-color: $gray-200; } }

.user-row--header {
  @include render-to(lg) {
    display: none; }

  &:hover {
    background-color: inherit; }

  [class*="user-row"] {
    font-size: rem(14);
    text-decoration: underline;
    color: $gray-600; } }

.user-row--employee {
  .user-row__login,
  .user-row__name {
    min-width: rem(150);
    max-width: rem(150); }

  .user-row__phone,
  .user-row__mobile {
    min-width: rem(120);
    max-width: rem(120); }

  .user-row__email {
    min-width: rem(210);
    max-width: rem(210); }

  .user-row__actions {
    min-width: rem(20);
    max-width: rem(20);
    padding-right: 0 !important;
    @include render-to(lg) {
      position: absolute;
      text-align: right !important;
      &::before {
        display: none !important; } }

    a {
      text-decoration: none;
      color: inherit; }

    .icon {
      font-size: rem(12);
      color: inherit; }

    .remove {
      &:hover {
        color: $red; } } } }

.user-row--shopper {
  .user-row__name,
  .user-row__phone {
    min-width: rem(200);
    max-width: rem(200); }

  .user-row__email {
    min-width: rem(280);
    max-width: rem(280); }

  .user-row__actions {
    min-width: rem(55);
    max-width: rem(55);
    padding-right: 0 !important;
    @include render-to(lg) {
      &::before {
        display: none !important; } } } }
