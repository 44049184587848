.switch-rectangle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .switch-rectangle__label {
    line-height: 1.33;
    @include render-for(compact-scr) {
      font-size: rem(15); } }

  .switch-rectangle__input[type="checkbox"] {
    display: none;
    &:checked + .switch-rectangle__switch {
      @include theme-color(border-color);
      &::before {
        left: 23px;
        @include theme-color(background-color); } } }

  .switch-rectangle__switch {
    position: relative;
    width: 45px;
    height: 20px;
    padding-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border: 1px solid #ced4da;
    &::before {
      content: "";
      display: block;
      top: 3px;
      bottom: 3px;
      left: 3px;
      right: auto;
      width: 17px;
      height: auto;
      border: 0;
      background-color: #ced4da;
      transition: all .2s ease-in-out; }
    &::after {
      content: none !important; } } }
