.sidebar {
  width: 100%;
  min-height: 100%;
  min-width: rem(220);
  max-width: rem(220);
  border-right: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  @include render-for(compact-scr) {
    min-width: 0;
    max-width: none;
    padding-top: rem(130); } }

.sidebar__content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  @include render-for(compact-scr) {
    padding: 0 rem(15); } }

.sidebar__brand {
  padding: rem(30) $sidebar-padding;
  width: 100%;
  text-align: center;
  @include render-for(compact-scr) {
    display: none; }
  img {
    width: auto;
    max-height: rem(45); } }

.sidebar__brand--secondary {
  padding: rem(5) rem(10);
  position: absolute;
  left: 0;
  top: 0;
  max-width: rem(80);
  max-height: rem(80); }

.sidebar__prices {
  padding: rem(30) $sidebar-padding 0;

  h6 {
    color: $gray-600;
    font-size: rem(15); } }

.sidebar__favorite-brands {
  width: 100%;
  position: relative;

  .sub-categories {
    @include render-for(compact-scr) {
      margin-top: rem(5); }
    @include render-to(sm) {
      margin-top: 0; }
    a {
      white-space: nowrap;
      @include render-for(large-scr) {
        padding: 0; }
      &:hover {
        @include theme-color(color); } } }
  a {
    text-decoration: none;
    font-size: rem(15); }

  h6 {
    color: $gray-600;
    padding: 0 $sidebar-padding;
    font-size: rem(15); }

  ul {
    margin: rem(15) 0;
    li {
      line-height: rem(24); }
    a {
      font-size: rem(15);
      padding: 0 $sidebar-padding;
      color: $text-color;
      &:hover {
        text-decoration: underline;
        @include render-for(large-scr) {
          @include theme-color(color); } } }
    @include render-for(compact-scr) {
      text-align: center;
      margin: rem(15) 0 rem(5);
      display: flex;
      flex-wrap: wrap;
      li {
        display: inline-block;
        width: 20%;
        padding: 0 rem(10); }
      a {
        font-size: rem(16);
        font-weight: 700;
        border-width: 1px;
        border-style: solid;
        @include theme-color(border-color);
        display: inline-block;
        width: 100%;
        padding: rem(5) 0;
        line-height: rem(24); } }
    @include render-to(sm) {
      margin-bottom: 0;
      li {
        width: 33%; }
      a {
        margin: rem(5) 0; } }
    @include render-to(xs) {
      li {
        width: 100%; } } }

  @include render-for(compact-scr) {
    margin-bottom: rem(20);
    h6 {
      font-size: rem(18);
      text-align: center;
      width: 100%;
      display: inline-block;
      @include theme-color(color); } } }

.sidebar__footer {
  padding: 0 $sidebar-padding;
  font-size: rem(12);
  margin-top: rem(30);
  width: 100%;
  @include render-for(compact-scr) {
    display: none; } }
