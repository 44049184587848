.price-label {
  font-size: rem(14);
  background-color: $gray-500;
  color: $white;
  border-radius: rem(20);
  margin: rem(4) 0;
  padding: 0 rem(13);
  line-height: rem(24);
  position: relative;
  font-weight: bold;
  max-width: fit-content;

  &::before {
    content: $icon-price-package;
    font-family: "icon-font";
    margin-right: rem(7); } }

.price-label--red {
  background-color: $icon-red-color; }

.price-label--green {
  background-color: $icon-green-color; }

.price-label--orange {
  background-color: $icon-orange-color; }
