.block--homepage-jumbotron {
  .block__content {
    margin-bottom: 0;
    margin-top: 0; }

  .block__content--homepage {
    max-width: rem(1150); }

  .home__main {
    margin: rem(30) 0 rem(55);
    @include render-to(sm) {
      margin-bottom: rem(50); } }

  .home__main__heading {
    font-size: 40px;
    margin-bottom: rem(40);
    @include render-to(sm) {
      text-align: center; }
    @include render-to(xs) {
      font-size: 35px; } }

  .home__icons__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: rem(70);
    @include render-to(sm) {
      flex-flow: column wrap;
      text-align: center; } }

  .home__icons {
    @include render-to(sm) {
      flex-flow: column wrap;
      display: flex;
      justify-content: center;

      .btn {
        margin-top: rem(18); } }

    .icon {
      font-size: 40px;
      margin-right: rem(18);
      @include render-to(sm) {
        margin: 0; } }

    span {
      font-size: 14px;
      display: inline-block;
      line-height: 1.2;
      vertical-align: super;
      @include render-to(sm) {
        margin-bottom: rem(18); } } }

  .home__icons--link {
    font-weight: 700;
    font-size: rem(15); }

  .home__icons--text {
    @include render-to(md) {
      flex: 1 0 100%;
      text-align: center;
      margin-bottom: rem(30); }
    @include render-to(sm) {
      margin-bottom: rem(18); }

    h4 {
      margin-bottom: rem(15); }

    a {
      font-size: 15px;
      color: #212428;
      &:hover {
        color: #ff7900; } } }

  .home__brands {
    margin-top: rem(75);

    .carousel--brands {
      .control-next {
        right: rem(-50);
        @include render-to(1250px) {
          right: 0; } }

      .carousel__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include render-to(1250px) {
          display: block; }

        a {
          padding: 0 18px;
          @include render-to(md) {
            padding: 0 rem(20); }

          &:first-child {
            padding-left: 0;
            @include render-to(md) {
              padding-left: rem(20); } }

          &:last-child {
            padding-right: 0;
            @include render-to(1250px) {
              padding-right: 20px; } } } } } }

  .single-box {
    box-shadow: 0 rem(20) rem(30) rgba(0, 0, 0, 0.07);
    text-align: center;
    border-radius: rem(15);
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin-bottom: rem(30);
    @include render-to(sm) {
      margin: 0 auto rem(30);
      max-width: rem(350); }

    .single-box__header {
      width: 100%;
      img {
        width: 100%; } }

    .single-box__body {
      width: 100%;
      font-size: 15px;
      padding: rem(40) rem(40) 0;

      h5 {
        margin-bottom: rem(30); }

      p {
        color: #212428; } }

    .single-box__body__labels {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding: 0 rem(30);

      span {
        font-size: 12px;
        color: #75878f;
        padding: 0 rem(8) rem(2);
        margin: rem(5);
        border: 1px solid #a0afb6;
        border-radius: 10px; } }

    .single-box__footer {
      width: 100%;
      margin-top: auto;
      padding: 0 rem(40) rem(40);

      a {
        font-size: 15px;
        position: relative;
        display: inline-block;
        margin-top: rem(55);

        &::after,
        &::before {
          position: absolute;
          content: '';
          top: rem(-20);
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: rem(4);
          height: rem(4);
          border-radius: rem(4);
          background-color: #a0afb6; }

        &::before {
          top: rem(-28); } } } } }

.left-flag {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: rem(-120);
    width: rem(100);
    top: 50%;
    height: 1px;
    @include theme-color(background-color);

    @include render-to(md) {
      content: none; } } }

.carousel--home {
  background-color: #fff;
  @include render-to(1250px) {
    padding: 0 rem(40); }
  @include render-to(sm) {
    padding: 0; }

  .carousel-inner {
    .carousel-item {
      background-color: #fff;
      align-items: stretch;

      & >  div {
        display: flex; }

      &.active {
        display: flex; } }

    .carousel-item-right {

      &.active {
        transform: translateX(33.33%);
        @include render-to(md) {
          transform: translateX(50%); }
        @include render-to(sm) {
          transform: translateX(100%); } } }

    .carousel-item-next {
      display: flex;
      transform: translateX(33.33%);
      @include render-to(md) {
          transform: translateX(50%); }
      @include render-to(sm) {
        transform: translateX(100%); } }

    .carousel-item-left {
      &.active {
        transform: translateX(-33.33%);
        @include render-to(md) {
          transform: translateX(-50%); }
        @include render-to(sm) {
          transform: translateX(-100%); } } }

    .carousel-item-prev {
      display: flex;
      transform: translateX(-33.33%);
      @include render-to(md) {
          transform: translateX(-50%); }
      @include render-to(sm) {
        transform: translateX(-100%); } }

    .carousel-item-right,
    .carousel-item-left {
      transform: translateX(0); } } }

.carousel--multiple {
  .carousel-control-next,
  .carousel-control-prev {
    text-decoration: none;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    width: rem(50);
    height: rem(50); }

  .carousel-control-next {
    @include render-to(1250px) {
      right: 0;
      left: auto; } }

  .carousel-control-prev {
    @include render-to(1250px) {
      left: 0;
      right: auto; } }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    font-size: 20px; }

  .carousel-control-prev-icon {
    transform: rotate(180deg);
    background-image: none; } }
