//
// Mixins
// -------------------------------

// media queries
@mixin render-for($type...) {
  @for $i from 1 through length($type) {
    @if nth($type, $i) == xs {
      @include media-breakpoint-only(xs) {
        @content; } }
    @else if nth($type, $i) == sm {
      @include media-breakpoint-only(sm) {
        @content; } }
    @else if nth($type, $i) == md {
      @include media-breakpoint-only(md) {
        @content; } }
    @else if nth($type, $i) == lg {
      @include media-breakpoint-only(lg) {
        @content; } }
    @else if nth($type, $i) == xl {
      @include media-breakpoint-only(xl) {
        @content; } }
    @else if nth($type, $i) == "navbar-lg" {
      @media screen and (min-width: $navbar-breakpoint + 1) {
        @content; } }
    @else if nth($type, $i) == "navbar-sm" {
      @media screen and (max-width: $navbar-breakpoint) {
        @content; } }
    @else if nth($type, $i) == "large-scr" {
      @media screen and (min-width: $compact-breakpoint + 1) {
        @content; } }
    @else if nth($type, $i) == "compact-scr" {
      @media screen and (max-width: $compact-breakpoint) {
        @content; } } } }

@mixin render-up($type...) {
  @for $i from 1 through length($type) {
    @if nth($type, $i) == xs {
      @include media-breakpoint-up(xs) {
        @content; } }
    @else if nth($type, $i) == sm {
      @include media-breakpoint-up(sm) {
        @content; } }
    @else if nth($type, $i) == md {
      @include media-breakpoint-up(md) {
        @content; } }
    @else if nth($type, $i) == lg {
      @include media-breakpoint-up(lg) {
        @content; } }
    @else if nth($type, $i) == xl {
      @include media-breakpoint-up(xl) {
        @content; } }
    @else {
      @media screen and (min-width: $type) {
        @content; } } } }

@mixin render-to($type...) {
  @for $i from 1 through length($type) {
    @if nth($type, $i) == xs {
      @include media-breakpoint-down(xs) {
        @content; } }
    @else if nth($type, $i) == sm {
      @include media-breakpoint-down(sm) {
        @content; } }
    @else if nth($type, $i) == md {
      @include media-breakpoint-down(md) {
        @content; } }
    @else if nth($type, $i) == lg {
      @include media-breakpoint-down(lg) {
        @content; } }
    @else {
      @media screen and (max-width: $type) {
        @content; } } } }

// css feeder
@mixin antialiasing($type:antialiased) {
  -webkit-font-smoothing: $type;
  font-smoothing: $type;
  font-smooth: always; }

@mixin hocus {
  &:hover,
  &:focus {
    @content; } }

@mixin acus {
  &:active,
  &:focus {
    @content; } }

@mixin achocus {
  &:active,
  &:hover,
  &:focus {
    @content; } }

@mixin all-abs($dist: 0) {
  position: absolute;
  top: $dist;
  right: $dist;
  bottom: $dist;
  left: $dist; }

@mixin all-fix($dist: 0) {
  position: fixed;
  top: $dist;
  right: $dist;
  bottom: $dist;
  left: $dist; }

@mixin theme-color($rule, $darken: 0%, $important: "false") {
  @if $important == true {
    $important: " !important"; }
  @else {
    $important: ""; }


  #{$rule}: darken($primary, $darken) #{$important};

  .theme-red & {
    #{$rule}: darken($brand-red, $darken) #{$important}; }

  .theme-carmine & {
    #{$rule}: darken($brand-carmine, $darken) #{$important}; }

  .theme-black & {
    #{$rule}: darken($brand-black, $darken) #{$important}; }

  .theme-yellow & {
    #{$rule}: darken($brand-yellow, $darken) #{$important}; }

  .theme-red_dark & {
    #{$rule}: darken($brand-red_dark, $darken) #{$important}; }

  .theme-broom & {
    #{$rule}: darken($brand-broom, $darken) #{$important}; }

  .theme-blue & {
    #{$rule}: darken($brand-blue, $darken) #{$important}; }

  .theme-golden_dark & {
    #{$rule}: darken($brand-golden_dark, $darken) #{$important}; }

  .theme-carrot_orange & {
    #{$rule}: darken($brand-carrot_orange, $darken) #{$important}; }

  .theme-green_atlantis & {
    #{$rule}: darken($brand-green_atlantis, $darken) #{$important}; }

  .theme-blue_cerulean & {
    #{$rule}: darken($brand-blue_cerulean, $darken) #{$important}; }

  .theme-blue_endevour & {
    #{$rule}: darken($brand-blue_endevour, $darken) #{$important}; } }

@mixin flex-width($width) {
  max-width: $width;
  flex-basis: $width; }
