//
// Pagination
// -------------------------------

.pagination {
  justify-content: center;
  color: $gray-400; }

.page-link {
  padding: rem(7) 0;
  width: rem(36);
  font-size: rem(16);
  text-align: center;
  text-decoration: none;
  border-color: $gray-200;
  border-radius: 0 !important;
  color: $text-color;
  @include render-to(xs) {
    width: rem(32); }
  &:hover {
    color: $text-color; } }

.page-link--prev,
.page-link--next {
  margin: 0 rem(10);
  font-size: rem(10);
  line-height: 1.9;
  @include theme-color(border-color);
  @include theme-color(background);
  &:hover {
    @include theme-color(border-color, 8%);
    @include theme-color(background, 8%); }

  .icon {
    color: white; } }

.page-item {
  &:last-child {
    .page-link {
      margin-right: 0; } }

  &.active {
    .page-link {
      font-weight: 700;
      border-color: $gray-200;
      background: transparent;
      @include theme-color(color); } }

  &.ellipsis {
    margin-left: rem(14);
    margin-right: rem(14);
    font-weight: bold;
    line-height: 1.8;
    letter-spacing: 5px; } }
