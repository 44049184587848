.block--info-toggle {
  @include render-to(md) {
    position: fixed;
    border-bottom: 1px solid $white; }

  p {
    color: $white;
    font-weight: 600;
    margin: rem(5) 0;
    padding-right: 5%; }

  a {
    color: $white;
    font-weight: 700; } }
