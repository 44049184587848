.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner--visible {
  overflow: visible; }

.carousel--brands {
  .carousel__content {
    position: relative;

    a {
      padding: 0 rem(29);
      @include render-to(md) {
        padding: 0 rem(20); }

      img {
        margin-bottom: rem(15);
        @include render-to(md) {
          max-height: rem(35); } }

      &:first-child {
        padding-left: 0; } } }

  .control-next {
    position: absolute;
    top: rem(10);
    right: 0;
    text-decoration: none; } }

.carousel--jumbotron {
  .carousel-item {
    opacity: 0;
    position: absolute;
    display: block;
    left: 0;
    overflow: hidden;
    z-index: 10;
    transform: none;
    transition: opacity 0.5s ease-in-out;
    @include render-to(xs) {
      padding-right: rem(30); }

    &.active {
      opacity: 1; }

    &.carousel-item-left,
    &.carousel-item-right {
      opacity: 0; }

    &.carousel-item-next,
    &.carousel-item-prev {
      opacity: 1; }

    .background {
      position: absolute;
      left: 50%;
      width: 100%;
      top: rem(100);
      z-index: -1;
      @include render-to(xs) {
        left: 47%; }

      img {
        position: relative;
        padding-right: rem(20);
        left: -50%;
        max-width: none;
        @include render-to(lg) {
          max-width: rem(400); }
        @include render-to(md) {
          max-width: rem(300); }
        @include render-to(sm) {
          max-width: rem(400); }
        @include render-to(xs) {
          max-width: rem(300); } } } }

  .carousel-indicators {
    display: block;
    left: auto;
    right: rem(15);
    top: rem(150);
    margin: 0;
    @include render-to(lg) {
      top: rem(80); }

    li {
      background-color: transparent;
      position: relative;
      text-indent: 0;
      font-size: rem(16);
      height: 1px;
      width: rem(25);
      text-align: center;
      padding-bottom: rem(40);
      margin-bottom: rem(15);
      border-bottom: 1px solid $gray-400;
      color: $gray-400;
      cursor: pointer;

      &.active {
        color: $text-color;
        border-bottom-color: $primary; }

      &::before, &::after {
        display: none; }

      &:last-child {
        border-bottom: none; }

      span {
        width: 100%; } } }

  .carousel__content {
    text-align: center;
    min-height: rem(450);
    @include render-to(md) {
      min-height: rem(300); }

    img {
      max-height: rem(500);
      max-width: rem(400);
      @include render-to(lg) {
        max-height: rem(400);
        max-width: rem(320); }
      @include render-to(md) {
        max-height: rem(320);
        max-width: rem(260); }
      @include render-to(sm) {
        max-height: rem(400);
        max-width: rem(320); }
      @include render-to(xs) {
        max-height: rem(320);
        max-width: rem(260); } } } }

.carousel-inner--modal .carousel-item {
  flex-wrap: wrap;
  justify-content: center; }

.carousel-inner--modal .carousel-item.active,
.carousel-inner--modal .carousel-item-next,
.carousel-inner--modal .carousel-item-prev {
  display: flex;
  @include flex-width(100%); }

.carousel-inner--modal .carousel-item-right.active,
.carousel-inner--modal .carousel-item-next {
  transform: translateX(50%);
  @include render-to(md) {
    transform: translateX(100%); } }

.carousel-inner--modal .carousel-item-left.active,
.carousel-inner--modal .carousel-item-prev {
  transform: translateX(-50%);
  @include render-to(md) {
    transform: translateX(-100%); } }

.carousel-inner--modal .carousel-item-right,
.carousel-inner--modal .carousel-item-left {
  transform: translateX(0); }

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
  width: 90px;
  @include render-to(xs, ms) {
    top: 101%;
    bottom: auto; }
  .icon {
    font-size: rem(32); } }

.carousel-control-prev {
  left: auto;
  right: 100%;
  @include render-to(xs, ms) {
    left: 0;
    right: auto; } }

.carousel-control-next {
  right: auto;
  left: 100%;
  @include render-to(xs, ms) {
    right: 0;
    left: auto; } }

.carousel--articles {
  @include render-to(1250px) {
    padding-right: rem(40); }
  .carousel-item {
    & >  div {
      display: flex; }

    &.active {
      display: flex; } }

  .carousel-item-next {
    display: flex;
    transform: translateX(50%);
    @include render-to(md) {
      transform: translateX(100%); } }

  .carousel-item-left {
    &.active {
      transform: translateX(-50%);
      @include render-to(md) {
        transform: translateX(-100%); } } }

  .carousel-item-prev {
    display: flex;
    transform: translateX(-50%);
    @include render-to(md) {
      transform: translateX(-100%); } }

  .carousel-item-right,
  .carousel-item-left {
    transform: translateX(0); } }
