//
// Grid
// -------------------------------

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: rem(15);
  padding-left: rem(15);
  width: 100%;
  max-width: 1140px; }

.container--big {
  margin-right: auto;
  margin-left: auto;
  padding-right: rem(15);
  padding-left: rem(15);
  width: 100%;
  max-width: 1310px; }

.container--full-flex {
  background-color: $white;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 1366px;
  min-height: 100vh;
  border-right: 1px solid $gray-200;
  @include render-for(compact-scr) {
    flex-direction: column; } }

.content--full-flex {
  flex: 1;
  max-width: calc(100% - #{rem(220)});
  @include render-for(compact-scr) {
    max-width: 100%;
    display: flex;
    flex-direction: column; }

  .block__content {
    max-width: none; }

  .box-gray {
    @include render-to(1300px) {
      padding: rem(25) rem(30); } }

  .block--pages-content {
    & > .block__content {
      @include render-for(large-scr) {
        max-width: 1140px;
        padding-left: rem(40); } } } }

.row--mb-50 {
  margin-bottom: rem(50); }

.row--spaced {
  margin-left: rem(-50px);
  margin-right: rem(-50px);
  @include media-breakpoint-down(lg) {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2); }

  & > [class*="col"] {
    padding-left: rem(50px);
    padding-right: rem(50px);
    @include media-breakpoint-down(lg) {
      padding-left: ($grid-gutter-width / 2);
      padding-right: ($grid-gutter-width / 2); } } }

.row--spaced-sm {
  margin-left: rem(-32px);
  margin-right: rem(-32px);
  @include media-breakpoint-down(lg) {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2); }

  & > [class*="col"] {
    padding-left: rem(32px);
    padding-right: rem(32px);
    @include media-breakpoint-down(lg) {
      padding-left: ($grid-gutter-width / 2);
      padding-right: ($grid-gutter-width / 2); } } }
