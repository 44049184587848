.panel {
  position: fixed;
  top: rem(150);
  @include render-to(1700px) {
    display: none; } }

.panel-left {
  left: calc((100% - 1650px) / 2); }

.panel-right {
  right: calc((100% - 1650px) / 2); }
