$categories-left-padding: rem(39);

.categories {
  margin-bottom: rem(50);
  width: 100%;
  @include render-for(compact-scr) {
    margin-bottom: rem(30); } }

.categories__item {
  padding: 0;
  position: relative;
  @include render-for(large-scr) {
    margin-left: $categories-left-padding;
    &:hover {
      .sub-categories {
        visibility: visible; }
      &::before {
        @include theme-color(background-color); } } }
  @include render-for(compact-scr) {
    text-align: center;
    @include theme-color(border-color);
    overflow: hidden; } }

.categories__item--heading {
  overflow: visible; }

.categories__item--no-border {
  border-bottom: none;
  margin-left: 0;
  @include render-for(large-scr) {
    margin-left: $categories-left-padding; } }

.categories__heading {
  display: block;
  text-decoration: none;
  position: relative;
  padding-top: rem(13);
  padding-bottom: rem(13);
  padding-right: $categories-left-padding;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  @include theme-color(color);
  border-bottom: 1px solid $gray-200;
  border-top: 1px solid $gray-200;
  margin-top: -1px;
  &:hover {
    @include theme-color(color);
    text-decoration: none; }
  @include render-for(compact-scr) {
    display: flex;
    justify-content: center;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center; }
  @include render-for(large-scr) {
    padding-right: rem(15);
    font-weight: 500;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 4px;
      display: block;
      background-color: transparent;
      transition-property: background-color;
      transition-delay: 0.3s; }

    &:hover::before {
      @include theme-color(background-color); } } }

.categories__heading-icon {
  position: absolute;
  left: -$categories-left-padding;
  top: -1px;
  bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $categories-left-padding;
  border-bottom: 1px solid;
  @include theme-color(border-bottom-color);
  border-top: 1px solid $gray-200;
  @include render-for(compact-scr) {
    position: static;
    padding-top: rem(10);
    padding-bottom: rem(10);
    border-top: 0; } }

.categories__link {
  rem(15) {}
  display: block;
  color: $text-color;
  text-decoration: none;
  font-size: rem(14);
  letter-spacing: -.7px;
  padding: rem(10) 0;
  border-bottom: 1px solid $gray-200;
  @include render-for(large-scr) {
    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 4px;
      display: block;
      background-color: transparent;
      transition-property: background-color;
      transition-delay: 0.3s; }
    &:hover::before {
      @include theme-color(background-color); } }
  @include render-for(compact-scr) {
    padding: rem(8) rem(10) rem(8) 0;
    z-index: 1;
    position: relative;
    width: 100%;
    background-color: $white;
    font-size: rem(15); }

  &:hover {
    text-decoration: none;
    color: $text-color; } }

.categories__toggler {
  @include theme-color(background-color);
  width: 100%;
  height: auto;
  padding: rem(20) rem(10);
  position: relative;
  top: auto;
  right: auto;
  font-size: rem(18);
  @include render-for(large-scr) {
    display: none; }
  @include render-for(compact-scr) {
    display: flex;
    justify-content: center; } }

.categories__toggler--menu {
  position: relative;
  display: block;
  padding: rem(11) rem(30);
  font-size: rem(15);
  font-weight: 500;
  text-align: left;
  @include render-for(compact-scr) {
    display: none; }
  &[aria-expanded="true"] {
    &::after {
      position: absolute;
      right: rem(18);
      bottom: 0;
      content: "";
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 6px solid #ffffff; } }

  .icon {
    position: absolute;
    right: rem(18);
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
    font-size: rem(12); } }

.categories__collapse {
  flex-direction: column;
  width: 100%;
  @include render-for(large-scr) {
    display: block; }
  @include render-for(compact-scr) {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: $gray-200;
    display: none !important; }
  &.show,
  &.collapsing {
    display: block !important; }
  &.collapsing {
    height: 0; } }

.categories__collapse--menu {
  @include render-for(large-scr) {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: #ffffff;
    z-index: 2; } }

.sidebar__catalog {
  flex: 1;
  &:hover {
    @include render-for(large-scr) {
      .categories--catalog {
        display: block; } } }

  .btn-catalog {
    color: $white !important;
    @include render-for(compact-scr) {
      display: none; } } }

.sidebar-collapse-wrapper {
  width: 100%;
  position: relative;
  z-index: 3;
  .categories__item {
    margin-left: 0; }

  .categories__item--no-border {
    margin-left: 0;
    @include render-for(large-scr) {
      margin-left: $categories-left-padding; } }

  .categories__link {
    padding-left: rem(30);
    padding-right: rem(30);
    font-size: rem(16); } }

.categories__collapse--catalog {
  position: relative; }

.categories--catalog {
  margin-bottom: 0;
  @include render-for(large-scr) {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); } }

input[type="checkbox"].categories__tab {
  display: block;
  visibility: visible;
  @include render-for(large-scr) {
    display: none; }
  @include render-for(compact-scr) {
    top: 0;
    left: 0;
    width: 100%;
    height: rem(50);
    z-index: 2;
    &:checked ~ .sub-categories {
      position: relative;
      bottom: rem(0);
      transition: bottom 0.25s ease;
      top: auto; }
    &:not(:checked) ~ .sub-categories {
      position: absolute;
      bottom: rem(50);
      top: auto; } } }

.categories__parent {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 0;
  color: $gray-600;
  &:hover {
    @include theme-color(color); }
  @include render-for(compact-scr) {
    display: none; } }

.sub-categories__list {
  position: relative;
  @include render-for(large-scr) {
    width: rem(200); }
  @include render-for(compact-scr) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(20);

    li {
      width: auto;
      flex: 1;
      margin: 0 rem(5);
      min-width: 30%;
      text-align: left;
      @include render-to(xs) {
        min-width: 100%;
        margin: 0; }

      a {
        font-size: rem(16);
        font-weight: 700;
        width: 100%;
        padding: rem(10);
        @include render-for(compact-scr) {
          border-bottom-width: 4px;
          border-bottom-style: solid;
          @include theme-color(border-bottom-color); } } } }

  @include render-for(large-scr) {
    li {
      &:first-child {
        a {
          padding-top: rem(20); } }
      &:last-child {
        a {
          padding-bottom: rem(20); } }
      a {
        margin: 0;
        padding: rem(10) rem(30);
        background-color: $gray-200; } } } }

.sub-categories {
  z-index: 100;
  @include render-for(large-scr) {
    position: absolute;
    left: 100%;
    transform: translate(0,-50%);
    top: 50%;
    margin: 0;
    visibility: hidden;
    transition-property: visibility;
    transition-delay: 0.3s;
    &:hover {
      visibility: visible; } }

  a {
    font-size: rem(15);
    color: $text-color;
    text-decoration: none;
    line-height: rem(16);
    padding: 0;
    margin: rem(5) 0;
    display: block;
    &:hover {
      text-decoration: underline; } } }

.sub-categories--top {
  @include render-for(large-scr) {
    transform: translate(0, -75%); } }

.sub-sub-categories__wrapper {
  &:hover {
    .sub-sub-categories {
      visibility: visible; } } }

.sub-sub-categories {
  @include render-for(large-scr) {
    visibility: hidden;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-left: 1px solid $gray-400;
    width: rem(200);
    transition-delay: 0.3s; }

  @include render-for(compact-scr) {
    li {
      min-width: none;
      max-width: none;

      a {
        font-weight: 400;
        border-bottom: none;
        margin: 0;
        padding-left: rem(20);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: rem(7);
          height: rem(7);
          position: absolute;
          left: rem(5);
          top: rem(15);
          @include theme-color(background-color); } } } } }
