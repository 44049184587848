.block__content--compare {
  border-top: 1px solid #e9ecef;
  .badge-primary {
    position: relative;
    top: rem(-4);
    margin-left: rem(10);
    padding: rem(3) rem(8);
    font-size: rem(15);
    border-radius: 50%; } }

.compare {
  max-width: 100%;
  overflow: auto;

  & > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    @include render-for(large-scr) {
      > tbody > tr {
        &:nth-child(even) {
          td {
            background-color: #f8f9fa; } } } } }

  thead {
    td {
      background-color: transparent;
      border-bottom: 1px solid #e9ecef; } } }

.compare--head {
  position: sticky;
  top: -1px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 10;

  &::-webkit-scrollbar {
    display: none; } }

.compare-control {
  background-color: #f8f9fa;
  padding: rem(21) rem(16) rem(25);
  h2 {
    margin-bottom: .5em; }
  @include render-for(large-scr) {
    display: none; } }

.compare-column {
  vertical-align: top;
  //min-width: rem(245)
  min-width: 23.94444rem;
  font-size: rem(15);
  transition: border-color 0.2s;
  &:not(:last-of-type) {
    border-right: 1px solid #e9ecef; }

  @include render-for(compact-scr) {
    table {
      width: 100%; }
    tr:nth-child(even) {
      td {
        background-color: #f8f9fa; } } }

  td {
    padding: rem(8) rem(25);
    @include render-for(compact-scr) {
      padding-left: rem(16);
      padding-right: rem(16); } }

  .product__content,
  .product__footer {
    padding: 0 rem(25);
    @include render-for(compact-scr) {
      padding-right: rem(16);
      padding-left: rem(16); } }

  .product__content {
    background: $white;
    transition: all 0.2s;
    border-bottom: 1px solid $white; }

  .product__info {
    position: static; }

  .product__buy {
    position: static;

    .btn {
      padding-top: rem(6);
      padding-bottom: rem(6); } }

  .product__img {
    margin-bottom: rem(25);
    padding: 0;
    height: rem(100);
    img {
      transform: scale(1);
      transition: transform 0.2s; } }

  .product__price {
    margin-bottom: rem(8);
    font-size: rem(16);
    font-weight: 700; }

  .product__desc {
    margin-bottom: rem(24);
    height: em(66, 15);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: margin 0.2s; }

  .in-stock {
    color: $text-color; }

  .product__price,
  .compare-column__controls,
  .product__footer {
    transition: all 0.2s; } }

.compare-column--disabled {
  font-weight: 700;
  color: #9fa8b2;
  .badge {
    padding: rem(1) rem(8) rem(2);
    border-radius: rem(9);
    border: 1px solid #ced4da;
    background-color: transparent;
    font-size: rem(12);
    line-height: 1;
    margin-left: rem(7);
    font-weight: 400; } }

.compare-column--product-head {
  position: relative;
  padding: rem(31) 0 rem(22); }

.compare-column--product-head,
.compare-column--parameters--head {
  z-index: 1;
  position: sticky;
  top: 0; }

.compare-column--parameters {
  position: sticky;
  left: 0;
  min-width: rem(287);
  padding: rem(8) rem(39);
  background-color: $white;
  z-index: 2;
  @include render-for(compact-scr) {
    display: none; } }

.compare-column--parameters--head {
  padding: 0; }

.compare-column__parameters {
  display: none;
  @include render-for(compact-scr) {
    display: table-row;
    font-weight: 700; } }

.compare-column__controls {
  position: absolute;
  left: rem(16);
  top: rem(16);
  right: rem(16);
  display: flex;
  justify-content: space-between;

  .icon {
    cursor: pointer; } }

.compare-colum__parameters-head {
  padding: rem(39);
  border-bottom: 1px solid $white;
  background: $white;
  transition: all 0.2s;

  h2 {
    margin-bottom: rem(180);
    transition: margin 0.2s; } }

.compare--head-is-stuck {
  .compare-colum__parameters-head {
    padding-bottom: rem(41);
    border-bottom-color: #e9ecef;

    h2 {
      margin-bottom: rem(30); } }

  .compare-column {
    border-bottom-color: transparent; }

  .product__content {
    margin-top: rem(-30);
    border-color: #e9ecef; }

  .product__desc {
    margin-top: rem(-68); }

  .product__img {
    img {
      transform: scale(0.5); } }

  .product__footer {
    padding-top: calc(#{rem(30)} + #{rem(68)});
    transition: opacity 0.1s, padding 0.2s; }

  .product__price,
  .compare-column__controls,
  .product__footer {
    opacity: 0;
    pointer-events: none; } }
