.modal {
  .btn {
    width: auto; } }

.modal-header,
.modal-body {
  @include render-to(xs, ms) {
    padding: 0 rem(5); } }

.modal-dialog {
  margin-top: 25vh;
  @include render-to(sm) {
    margin-top: 10vh; } }

.modal-content {
  border-radius: 0;
  padding: rem(60) 0;
  display: block; }

.modal-header {
  justify-content: center; }

.modal__close {
  width: rem(22);
  height: rem(22);
  position: absolute;
  right: rem(24);
  top: rem(24);
  background-color: transparent;
  border: none;
  transition: transform .2s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.1); }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #000;
    margin-top: -2px; }

  &::before {
    transform: rotate(45deg); }
  &::after {
    transform: rotate(-45deg); } }
