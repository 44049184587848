.labels {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-5);
  padding-bottom: rem(14);
  @include render-for(compact-scr) {
    padding-top: rem(14);
    flex-wrap: nowrap;
    overflow: visible;
    overflow-x: scroll; } }

.label {
  white-space: nowrap;
  position: relative;
  margin: rem(5);
  padding: rem(4) rem(35) rem(4) rem(14);
  border: 1px solid #ced4da;
  border-radius: rem(14);
  font-size: rem(13);
  font-weight: 500; }

.label__close {
  position: absolute;
  top: 50%;
  transform: translateY(-48%);
  right: rem(9);
  padding: rem(5);
  text-decoration: none;
  font-size: rem(11);
  &:hover {
    text-decoration: none; } }
