.table-wrapper {
  overflow-y: auto; }

.table-bordered {
  width: 100%;

  th, td {
    padding: em(12, 16) em(5, 16) em(12, 16) em(15, 16);
    font-size: rem(16);
    border-bottom: 1px solid darken($gray-100, 5%);
    color: $gray-600;
    @include render-to(xs) {
      font-size: rem(14); } }

  tr:hover {
    th, td {
      background: $gray-100; } }

  thead {
    th, td {
      font-weight: 500;
      font-size: rem(14);
      white-space: nowrap;
      @include render-to(xs) {
        font-size: rem(13); } }

    tr:hover {
      th, td {
        background: transparent; } }

    a {
      text-decoration: none;

      span {
        text-decoration: underline; } } }

  .cell--wide {
    min-width: rem(150); }

  a {
    color: inherit;
    &:hover {
      color: $primary; } }

  .sorted a {
    color: $primary;
    &::after {
      margin-left: rem(4);
      font-size: rem(8);
      font-family: "icon-font"; } }

  .sorted--asc a {
    &:after {
      content: $icon-dart-up; } }

  .sorted--desc a {
    &:after {
      content: $icon-dart-bottom; } } }

.table-bordered--light {
  tbody {
    tr:not(:first-child) {
      th, td {
        padding-top: rem(2); } }

    tr:not(:last-child) {
      th, td {
        padding-bottom: rem(2);
        border-bottom: none; } } } }
