.filter {
  padding-top: rem(20);
  font-size: rem(16);

  a {
    @include theme-color(color);
    &:hover {
      @include theme-color(color, 5%); } }

  .box-flex {
    @include render-to(1200px) {
      flex-wrap: wrap; } }

  .box-search {
    position: relative;

    input {
      padding-top: rem(6);
      padding-bottom: rem(6); }

    .search-button {
      top: 0; } }

  .form-group {
    @include render-to(sm) {
      display: block;
      label {
        margin-bottom: rem(10);
        justify-content: left; }
      select {
        width: 100%; } } }

  .form-group--checkbox {
    min-height: rem(37);
    @include render-to(sm) {
      display: inline-block !important;
      width: auto;
      margin-right: rem(20); } }

  .form-group-search-holder {
    width: 100%; }

  .list-inline {
    margin-bottom: 0; } }

.filter__left,
.filter__right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include render-to(1200px) {
    min-width: 100%;
    flex: 0 1 auto; } }

.filter__left {
  flex: auto;

  .form-group {
    margin-right: rem(20);
    margin-bottom: rem(15);
    @include render-to(sm) {
      &:not(.form-group--checkbox) {
        margin-right: 0;
        width: 100%; } } } }

.filter__right {
  justify-content: flex-end;
  flex: auto;
  position: relative;
  min-width: rem(280);
  @include render-to(1200px) {
    justify-content: space-between; }

  .form-group {
    margin-left: rem(20);
    margin-bottom: rem(15);
    flex: auto;
    justify-content: space-between;
    flex-wrap: nowrap;
    @include render-to(1200px) {
      flex: 0 1 auto;
      &:first-child {
        margin-left: 0; } }
    @include render-to(sm) {
      margin-left: 0;
      flex-direction: row;
      width: 100%; } } }

.filter__controls {
  display: flex;
  min-height: rem(34);
  margin-left: rem(5);
  @include render-to(sm) {
    margin-left: 0; } }

.filter__dropdown {
  border: 1px solid $gray-400;
  padding: 0;
  margin-right: -1px;
  @include render-to(sm) {
    margin-bottom: rem(10);
    .btn {
      width: 100%;
      text-align: left; } }

  &:hover {
    .btn::before {
      @include theme-color(color); } }

  .dropdown-menu {
    border-radius: 0;
    padding: rem(25) rem(25) rem(10);
    font-size: rem(16);
    width: rem(300);
    margin-top: 1px;
    margin-left: -1px;
    @include render-to(sm) {
      width: 100%; }

    &.show,
    &:hover {
      display: block; }

    .form-group {
      display: inline-block; } }

  .btn {
    background-color: $white;
    padding: rem(6) rem(30) rem(6) rem(8);
    position: relative;
    &::after {
      display: none; }
    &::before {
      content: $icon-arrow-down;
      font-family: "icon-font";
      font-size: rem(5);
      color: $gray-600;
      position: absolute;
      right: rem(10);
      top: rem(15); } } }

.filter__clear {
  font-size: rem(15);
  font-weight: bold;
  text-decoration: none;
  position: relative;
  padding-left: rem(20);
  margin-left: rem(10);
  @include render-to(sm) {
    margin: rem(10) 0;
    display: block;
    font-size: rem(16);
    padding-left: rem(25); }

  &:hover {
    text-decoration: none; }

  i {
    position: absolute;
    top: 1px;
    left: 0;
    @include render-to(sm) {
      top: -1px; } } }

.filters-control {
  display: none;
  @include render-for(compact-scr) {
    display: flex;
    align-items: center;
    padding-top: rem(12);
    padding-bottom: rem(24);

    .btn-filter {
      margin-right: rem(35); } } }
