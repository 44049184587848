//
// Foundation
// -------------------------------

html {
  height: 100%; }

body {
  padding-top: rem(0);
  padding-bottom: rem(60);
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  @include antialiasing; }

.page--e-shop {
  padding-bottom: 0; }
