.block--login {
  font-family: 'Roboto', sans-serif;
  z-index: 14;
  background-color: $primary;
  color: $white;
  @include render-to(sm) {
    position: fixed; }

  .block__content {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0 auto;
    padding: rem(15);
    justify-content: space-between; }

  h5 {
    margin-bottom: 0;
    @include render-to(sm) {
      width: 100%;
      text-align: center; } }

  .form-group {
    flex-flow: row;
    margin-left: rem(20);
    flex: 1 auto; }

  .form-control {
    border: none;
    width: 100%;
    margin-right: rem(15);
    line-height: rem(20);
    @include render-up(1120px) {
      min-width: rem(235); }
    @include render-to(sm) {
      margin-bottom: rem(5); } }

  .btn {
    background-color: #5c310b;
    color: $white;
    padding: rem(11) rem(21); }

  .login-links {
    margin-left: rem(30);
    padding-left: rem(15);
    line-height: rem(35);
    border-left: 1px solid rgba(255, 255, 255, .75);
    @include render-to(1100px) {
      width: 100%;
      border-left: none;
      padding-left: 0;
      margin-left: 0;
      text-align: center;
      margin-top: rem(10); }

    a {
      font-size: rem(14);
      padding: 0 rem(15);
      display: inline;
      color: $white;
      font-weight: 700;
      @include render-to(md) {
        font-size: rem(16);
        padding: 0 rem(10); } } }

  i {
    color: $white;
    font-size: rem(22);
    margin-left: rem(15);
    line-height: rem(35);
    cursor: pointer; } }

