.mt-10 {
  margin-top: rem(10); }

.mt-20 {
  margin-top: rem(20); }

.mt-30 {
  margin-top: rem(30); }

.mt-50 {
  margin-top: rem(50); }

.mb-0 {
  margin-bottom: 0; }

.mb-10 {
  margin-bottom: rem(10); }

.mb-20 {
  margin-bottom: rem(20); }


.mb-30 {
  margin-bottom: rem(30); }

.mb-50 {
  margin-bottom: rem(50); }

.mb-sm-0 {
  @include render-up(sm) {
    margin-bottom: rem(0); } }

.mh-50 {
  margin: 0 rem(50); }

.mr-10 {
  margin-right: rem(10); }

.mr-30 {
  margin-right: rem(30); }

.ml-10 {
  margin-left: rem(10); }
