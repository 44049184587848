//
// Functions
// -------------------------------

@function getNumber($string) {
  $strings: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
  $numbers: 0 1 2 3 4 5 6 7 8 9;
  $result: 0;

  @for $i from 1 through str-length($string) {
    $character: str-slice($string, $i, $i);
    $index: index($strings, $character);

    @if $index {
      $number: nth($numbers, $index);
      $result: $result * 10 + $number; } }

  @if str-slice($string, 1, 1) == "-" {
    $result: $result * -1; }

  @return $result; }

// calculate rem size from pixel size
@function rem($px) {
  $default: getNumber(inspect($font-size-base));
  @return getNumber(inspect($px)) / $default * 1rem; }

// calculate em size from pixel size
@function em($px, $parent: getNumber(inspect($font-size-base))) {
  @return $px / $parent * 1em; }
