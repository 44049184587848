.product-overview-empty {
  margin: 0 auto;
  max-width: rem(650);
  display: flex;
  align-items: center; }

.product-overview-empty__text {
  padding-left: rem(68);
  @include render-to(md) {
    padding-left: rem(35); }
  @include render-to(xs) {
    padding-left: rem(25); }

  h3 {
    margin: 0 0 rem(10);
    font-size: rem(33);
    letter-spacing: -1px;
    @include render-to(md) {
      font-size: rem(26); }
    @include render-to(xs) {
      font-size: rem(19); } }

  p {
    margin-bottom: rem(30);
    @include render-to(xs) {
      font-size: rem(16); } } }

.product-overview-empty__img {
  width: rem(174);
  order: -1;
  @include render-to(md) {
    width: rem(130); }
  @include render-to(xs) {
    width: rem(80); } }
