//
// Buttons
// -------------------------------

// links
.link {
  text-decoration: none;
  font-weight: 700;
  &::after {
    position: relative;
    top: rem(-2px);
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-right: 2px solid $blue;
    border-top: 2px solid $blue;
    transform: rotate(45deg);
    margin-left: rem(8px); } }

.link--white {
  color: $white;
  &::after {
    border-color: $white; } }

// buttons
.btn {
  letter-spacing: -0.04em;
  font-size: rem(16px);
  border: 0;
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;
  @include media-breakpoint-down(xs) {
    width: 100%; }
  @include achocus {
    text-decoration: none;
    box-shadow: none; }

  &::after {
    border-color: $white; }

  .icon {
    line-height: 1;
    color: inherit; } }

.btn-primary {
  @include theme-color(background-color);
  color: $white;
  font-weight: 700;
  @include hocus {
    color: $white;
    @include theme-color(background-color, 5%); }
  &:active {
    @include theme-color(background-color, 15%, true); } }

.btn-gray {
  background-color: $gray-400;
  color: $white;
  font-weight: bold;
  @include achocus {
    background-color: $gray-500;
    color: $white; } }

.btn-plain {
  position: relative;
  color: $text-color;
  background-color: transparent;
  .icon {
    @include theme-color(color); }
  @include achocus {
    color: $black; }
  &.strong {
    font-weight: 700; } }

.btn-plain--primary {
  color: $primary; }

.btn-lg {
  line-height: rem(20); }

.btn-xlg {
  font-size: rem(18);
  padding: rem(15) rem(20); }

.btn-sm {
  font-size: rem(14); }

.btn-form-control {
  border: 1px solid $gray-400;
  background-color: $white;
  line-height: rem(5);
  padding: rem(11);
  min-width: rem(34);
  height: rem(34);
  margin-right: -1px;
  width: auto;

  &:hover,
  &.active {
    @include theme-color(background-color);
    @include theme-color(border-color);
    i {
      color: $white; } }
  .icon {
    color: $gray-400;
    font-size: rem(5); } }

.btn-icon {
  margin-left: rem(10);
  &:first-child {
    margin-left: 0; }
  .icon {
    font-size: 80%;
    opacity: .75; } }

.btn-icon-right {
  .icon {
    margin-left: rem(15); } }

.btn-icon-left {
  .icon {
    margin-right: rem(15); } }

.btn-big-icon {
  line-height: rem(18);
  padding: rem(5) rem(7);
  .icon {
    display: inline-block;
    margin-top: 2px;
    font-size: rem(18); } }

.btn-catalog {
  @include render-for(compact-scr) {
    margin-bottom: 1px; } }

.btn-filter {
  display: inline-flex;
  align-items: baseline;
  @include render-for(compact-scr) {
    width: auto; }

  .icon {
    font-size: rem(12);
    margin-right: rem(6); }

  .badge {
    margin-left: rem(16);
    padding: rem(0) rem(7);
    background-color: #ca5f00;
    font-size: rem(11);
    color: #ffffff; } }
