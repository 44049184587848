//
// Counter
// -------------------------------

.counter {
  margin-right: rem(15);
  width: rem(18);
  height: rem(36);
  display: inline-block;
  vertical-align: top; }

.counter__plus,
.counter__minus {
  height: 50%;
  font-size: rem(15);
  line-height: rem(15);
  text-decoration: none;
  text-align: center;
  display: block;
  border: 1px solid $gray-form-border;
  color: darken($gray-500, 5%);
  &:hover {
    text-decoration: none;
    background: $gray-form-border;
    color: darken($gray-500, 10%); } }

.counter__minus {
  border-top: none; }
