.flex-wrapper {
  display: flex;
  @include render-to(sm) {
    display: block;
    width: 100%; } }

.select-wrapper {
  position: relative;
  background-color: $white;
  z-index: 0;
  &::after {
    content: $icon-arrow-down;
    font-family: "icon-font";
    font-size: rem(5);
    color: $gray-600;
    position: absolute;
    right: rem(10);
    top: 50%;
    transform: translateY(-50%);
    z-index: -1; }
  @include render-to(sm) {
    margin-bottom: rem(10); }

  select {
    @include achocus {
      background-color: transparent; }
    @include render-to(sm) {
      width: 100%; } } }

.date-wrapper {
  position: relative;
  background-color: $white;
  z-index: 0;
  @include render-to(sm) {
    margin-bottom: rem(5); }

  &::after {
    content: $icon-calendar;
    font-family: "icon-font";
    font-size: rem(25);
    color: $primary;
    position: absolute;
    right: rem(8);
    top: 50%;
    transform: translateY(-51%);
    z-index: -1; }

  & + .date-wrapper {
    margin-left: rem(5);
    @include render-to(sm) {
      margin-left: 0; } }

  .form-control {
    min-width: auto !important;
    padding-right: rem(40);
    width: rem(100);
    background-color: transparent;
    @include render-to(sm) {
      width: 100%; } } }
