//
// Blocks
// -------------------------------

.block {
  position: relative;
  padding: 1px 0;
  z-index: 10; }

.block__content {
  @extend .container;
  margin-top: rem(70);
  margin-bottom: rem(50);
  position: relative;
  z-index: 10;
  @include render-for(compact-scr) {
    margin-top: rem(60);
    margin-bottom: rem(44); }
  @include render-to(ms) {
    margin-top: rem(50);
    margin-bottom: rem(30); }

  &::before,
  &::after {
    display: none; } }

.block--big {
  .block__content {
    @extend .container--big; } }

.block__head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: rem(50);
  @include render-to(xs) {
    flex-direction: column;
    a {
      margin-top: rem(20); } }

  .h2 {
    margin-bottom: 0; } }

.block__head--cart {
  justify-content: flex-end;
  font-size: rem(16);
  margin: rem(15) 0;
  a {
    margin-left: rem(10);
    @include render-to(xs) {
      margin-left: 0;
      margin-top: rem(10); } } }


.block--plain-content {
  & .block__content {
    margin: 0 auto; } }

.block--brands-slider {
  .block__content {
    margin-top: rem(20);

    @media (min-height: 880px) {
      margin-top: rem(100); }

    @media (min-height: 960px) {
      margin-top: rem(200); } } }

.block--pages-content {
  .block__content {
    margin-top: rem(30); } }

.block--brand-subcategory,
.block--brand-billboard {
  padding: 0; }

.block--brand-subcategory {
  @include render-for(compact-scr) {
    order: -1; } }

.block--gray {
  background-color: $gray-100; }

.block--theme {
  @include theme-color(background-color);
  &.block--brand-subcategory {
    @include render-for(compact-scr) {
      background-color: $white;
      padding: 1px 0; } } }

.block--labels {
  background-color: $gray-100;
  @include render-for(compact-scr) {
    background-color: #ffffff; } }

.block__content--compare {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0; }
