.cart__footer {
  border-bottom: 1px solid $gray-200;
  padding: rem(30) 0; }

.cart__prices {
  padding: rem(0) rem(20);
  @include render-to(xs) {
    margin-bottom: rem(20); }

  small,
  span, {
    display: block;
    font-weight: bold;
    text-align: right; }

  small {
    font-size: rem(16);
    color: $gray-600; }

  span {
    font-size: rem(22); } }

.cart__prices--label {
  small,
  span, {
    font-weight: normal;
    text-align: left; } }

.cart__message {
  flex: 1;
  max-width: 100%;
  padding-left: rem(50);
  padding-right: rem(10);
  font-size: rem(16);
  @include render-to(lg) {
    padding-left: rem(15); }
  @include render-to(xs) {
    flex: 1 auto;
    margin-bottom: rem(20); }
  p {
    margin-bottom: 0; } }
