.slider {
  padding-top: rem(10); }

.slider__inputs {
  margin-top: rem(25);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-control {
    font-size: rem(12); } }

.slider__inputs-range {
  margin-left: rem(6);
  margin-right: rem(6);
  min-width: rem(4);
  height: 2px;
  background-color: #e1e1e1; }

.ui-slider-horizontal {
  height: 5px; }

.ui-widget.ui-widget-content {
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 0;
  border: 1px solid #e1e1e1;
  background-color: #ffffff; }

.ui-slider .ui-slider-range {
  top: -1px;
  height: 5px;
  border: 0;
  @include theme-color(background-color); }

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -7px;
  top: -6px; }

.ui-state-default,
.ui-widget-content .ui-state-default {
  width: 14px;
  height: 14px;
  border: 2px solid #ffffff;
  @include theme-color(background-color);
  border-radius: 50%; }
