.badge {
  display: inline-block;
  color: #8f8f8f;
  font-size: 11px;
  font-weight: 500;
  background-color: #efefef;
  padding: rem(2) rem(7);
  border-radius: rem(9); }

.badge-primary {
  color: $white;
  @include theme-color(background-color); }
