
.lds-dual-ring div {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  border: 10px solid #000;
  border-color: #ff7900 transparent #ff7900 transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

.lds-dual-ring {
  margin: rem(40) auto;
  position: relative;
  width: 95px !important;
  height: 95px !important;
  transform: translate(-47.5px, -47.5px) scale(0.475) translate(47.5px, 47.5px); }
