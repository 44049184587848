//
// Form checkbox
// -------------------------------

.checkbox {
  position: relative;

  label {
    margin: 0; }

  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden; }

  input[type="checkbox"]:checked + label {
    &::after {
      opacity: 1;
      transform: scale(1);
      transition-duration: 0.3s; } }

  & + .help-block {
    padding-left: 33px; } }

.checkbox label {
  padding-left: 33px;
  position: relative;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $gray-100;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    .has-error & {
      border-color: $red; } }

  &::after {
    content: image_url('check.svg');
    margin: 0 4px;
    width: 12px;
    position: absolute;
    top: -1px;
    left: 0;
    opacity: 0;
    transform: scale(0.3);
    transition: all 0.1s ease; }

  &.checked {
    &::after {
      opacity: 1;
      transform: scale(1);
      transition-duration: 0.3s; } } }
