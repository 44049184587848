
.block--error {
  .block__content {
    margin-top: rem(200);
    max-width: rem(600);
    @include render-to(sm) {
      text-align: center;
      margin-top: rem(50); } }

  i {
    font-size: rem(220);
    @include render-to(sm) {
      font-size: rem(180); } }

  h1 {
    font-size: rem(120);
    margin: 0;
    display: inline-block;
    margin-top: rem(-50);
    @include render-to(sm) {
      font-size: rem(100); } }

  .h3 {
    font-size: rem(35);
    @include render-to(sm) {
      font-size: rem(30); } } }
