.navbar {
  padding: $spacer * 2.5 0;
  @include render-for(navbar-sm) {
    padding: rem(16) 0; } }

.navbar--eshop {
  padding: 0;
  display: block;

  .navbar-brand {
    @include render-for(large-scr) {
      display: none; }

    img {
      max-height: rem(30); } }

  .nav li a {
    margin: 0 rem(10);
    &::before {
      top: -25px;
      @include render-to($navbar-eshop-breakpoint-lg) {
        top: -18px; }
      @include render-to(1200px) {
        top: -8px; }
      @include render-for($navbar-eshop-breakpoint-md) {
        top: -13px; } } }

  .navbar-toggler {
    top: rem(15);
    right: rem(15); }

  .menu {
    right: rem(65);
    top: rem(18); } }

.primary-navbar {
  display: flex;
  align-items: center;
  padding: 0 0 0 rem(21);
  background-color: #efefef;
  @include render-to($navbar-breakpoint) {
    background-color: transparent;
    padding: rem(20) rem(15) rem(0); }

  .nav {
    margin-left: auto; }

  .nav-item {
    padding-top: rem(7);
    padding-bottom: rem(7);
    @include render-up($navbar-breakpoint + 1) {
      &:not(:last-of-type) {
        .nav-link::after {
          content: "";
          position: absolute;
          top: 55%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 14px;
          background-color: #cccccc; } } } }

  .nav-link {
    position: relative;
    padding-top: rem(5);
    padding-bottom: rem(5); }

  .nav li {
    a {
      margin: 0;
      padding: 0 rem(20); }

    .nav-link--active {
      color: $primary; } } }

.secondary-navbar {
  display: flex;
  justify-content: flex-end;
  padding: rem(18) rem(30);
  @include render-to($navbar-breakpoint) {
    padding-left: rem(15);
    padding-right: rem(15); }

  .box-actions {
    margin-left: rem(25);
    flex-direction: row;

    &:nth-child(2) {
      @include render-for($navbar-eshop-breakpoint-md) {
        display: initial; }
      @include render-for(navbar-sm) {
        display: none; } } } }

.nav {
  @include render-for(navbar-sm) {
    flex-direction: column;
    align-items: center;
    padding: rem(40); }

  li {
    display: inline-table;
    @include render-for(navbar-sm) {
      margin: rem(5) 0; } }

  li a {
    padding: 0;
    margin: 0 $spacer;
    color: $text-color;
    font-size: rem(15);
    font-weight: 400;
    position: relative;
    text-decoration: none;
    @include render-for(navbar-sm) {
      margin: rem(10) 0;
      text-align: center;
      font-size: rem(26); }
    @include render-to(lg) {
      padding-top: rem(10);
      margin: 0 rem(10); }
    @include render-to(md) {
      margin: 0 rem(13); }

    &:hover,
    &.active:hover {
      @include theme-color(color);
      &::before {
        @include render-for(navbar-lg) {
          display: block; } } }

    &.active::before {
      @include render-for(navbar-lg) {
        display: block; } } }

  & + .box-actions {
    @include render-to($navbar-eshop-breakpoint-md - 1) {
      display: none; }
    @include render-for(navbar-sm) {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: rem(-15);
      .btn-plain {
        font-size: rem(24);
        padding: rem(20);
        border-top-width: 1px;
        border-top-style: solid;
        @include theme-color(border-top-color);
        i {
          font-size: rem(28); } } } } }

.nav-link {
  display: inline; }

.navbar-toggler.collapsed ~ .navbar-collapse.collapsing {
  @include render-for(navbar-sm) {
    top: -100% !important;
    transition: top .4s cubic-bezier(0.38, 0, 1, 0.6);
    right: 0;
    left: 0; } }

.navbar-collapse {
  justify-content: space-between;
  z-index: 1;
  @include render-to($navbar-eshop-breakpoint-md - 1) {
    align-items: flex-start;
    justify-content: flex-start; }
  @include render-for(navbar-sm) {
    top: -100%;
    position: fixed;
    display: flex;
    height: 100%;
    transition: top .3s ease; }

  &.show,
  &.collapsing {
    @include render-for(navbar-sm) {
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.95);
      overflow: hidden;

      .nav,
      .nav li,
      .box-search {
        width: 100%; }

      .lang-item li {
        width: auto; }

      .nav {
        flex-direction: row;
        text-align: center; } } }

  &.show {
    @include render-for(navbar-sm) {
      top: 0; } }

  &.collapsing {
    @include render-for(navbar-sm) {
      height: 100% !important;
      top: 0; } } }

.navbar-brand {
  float: left;
  padding: 0;
  margin-right: rem(15);
  flex-shrink: 0;
  line-height: rem(10); }

.navbar-toggler {
  position: absolute;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  top: rem(16);
  right: 0;
  width: rem(36);
  height: rem(36);
  outline: none;
  z-index: 9;

  &:not(.collapsed) {
    position: fixed;
    top: rem(15);
    right: rem(15);

    .navbar-toggler-burger-line {
      margin: 0;
      width: 120%;

      &:nth-child(1) {
        right: rem(5);
        transform: rotate(-45deg);
        transform-origin: 100%; }

      &:nth-child(2) {
        display: none; }

      &:nth-child(3) {
        bottom: 0;
        left: -8px;
        transform: rotate(45deg);
        transform-origin: 83%; } } }

  .navbar-toggler-burger-line {
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    float: left;
    line-height: 0;
    transition: transform 0.4s cubic-bezier(0, 0.5, 0.5, 1);
    @include theme-color(background-color);

    &:nth-child(1) {
      top: 0; }

    &:nth-child(2) {
      top: rem(12); }

    &:nth-child(3) {
      top: rem(24); } } }

.menu {
  content: "Menu";
  font-weight: 700;
  font-size: rem(18);
  position: absolute;
  right: rem(50);
  top: rem(20);
  @include render-for(navbar-lg) {
    display: none; } }

.lang-item {
  position: relative;
  margin-left: rem(20);
  padding-left: rem(20);
  border-left: 1px solid $gray-500;
  @include render-to(lg) {
    display: block;
    width: 100%;
    border-left: none;
    padding-left: rem(10);
    padding-top: rem(25);
    margin-left: 0; }
  @include render-for(navbar-sm) {
    text-align: center;
    padding-left: 0; }

  &::before {
    @include render-to(lg) {
      content: " ";
      position: absolute;
      top: rem(15);
      width: rem(120);
      height: 1px;
      background-color: $gray-500;
      display: block; }
    @include render-for(navbar-sm) {
      width: 100%; } }

  li {
    display: inline-block;

    a {
      color: $gray-600;
      text-decoration: line-through;
      margin: 0 rem(5);
      &:hover {
        &::before {
          display: none; } }
      @include achocus {
        color: $gray-600;
        text-decoration: line-through; }

      &.active {
        text-decoration: none;
        color: $text-color;
        &::before,
        &:hover::before {
          display: none; }
        @include hocus {
          @include theme-color(color); } } } } }

.navbar-expand-md .navbar-toggler {
  @include render-to($navbar-breakpoint) {
    display: block; } }
