.product-detail__gallery-wrapper {
  @include render-up(md) {
    padding-right: rem(80); }
  @include render-to(sm) {
    order: 2; } }

.product-detail__gallery {
  min-height: rem(400);
  display: flex;
  align-items: center;
  justify-content: center; }

.product-detail__heading {
   margin: rem(15) 0 rem(10) 0; }

.product-detail__reference {
  display: inline-block;
  font-weight: 700;
  color: $gray-600;
  margin-bottom: rem(30); }

.product-detail__logo {
  display: block;
  max-width: rem(100);
  max-height: rem(35);
  margin-bottom: rem(15); }

.product-detail__info {
  .item {
    text-decoration: none; }

  .icon {
    font-size: rem(21);
    color: $gray-icon;
    &:hover {
      color: #fe254a; } }

  .icon-heart-fill {
    color: #fe254a; } }

.product__labels-and-info {
  display: flex;
  align-items: center; }
