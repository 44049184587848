.article-preview {
  display: flex;
  flex-wrap: nowrap; }

.article-preview__image {
  max-width: rem(50);
  flex: 0 0 rem(50);
  margin-right: rem(15);
  @include render-up(481px) {
    max-width: rem(89);
    flex: 0 0 rem(89);
    margin-right: rem(23); } }

.article-preview__text {
  .h5 {
    margin-top: 0;
    margin-bottom: .2em;
    font-size: rem(15); }

  p {
    margin-bottom: .5em;
    font-size: rem(15);
    line-height: 1.4;
    color: #212428; } }

.article-preview__date {
  margin-bottom: .5em;
  color: #75878f;
  font-size: 12px; }

.article-preview__link {
  @extend .icon;
  position: relative;
  color: #f07e20;
  font-weight: 700;
  font-size: rem(15) !important;
  text-decoration: none;
  span {
    text-decoration: underline; }
  &::before {
    content: $icon-arrow-down;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    left: calc(100% + 10px);
    font-size: 8px; } }
