footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  padding: rem(15);
  font-size: rem(14);
  color: $gray-600;
  border-top: 1px solid $gray-200;
  @include render-to(xs) {
    flex-direction: column;
    text-align: center;
    span {
      margin: rem(5) 0; } }
  a {
    margin-left: rem(5); } }

.footer--hidden {
  display: none; }
