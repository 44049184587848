.rollup {
  position: relative;

  input[type="checkbox"] {
    visibility: visible;
    width: 100%;
    height: rem(30);
    position: absolute;
    left: 0;
    z-index: 10;

    &:checked ~ .rollup__content {
      display: block; }

    &:checked ~ .rollup__toggle::after {
      transform: rotateX(180deg); }

    &:checked ~ .rollup__toggle--filters::after {
      transform: translateY(-40%) scale(0.8) rotate(-180deg); } } }

.rollup__toggle {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  &::after {
    content: $icon-arrow-down-bold;
    font-family: "icon-font";
    font-size: rem(8);
    position: absolute;
    left: calc(100% + #{rem(5)});
    top: rem(7);
    transition: all .15s linear; } }

.rollup__content {
  display: none;
  margin-top: rem(10); }

.rollup__toggle--filters {
  font-size: rem(13);
  @include theme-color(color);
  text-decoration: underline;
  &::after {
    content: $icon-arrow-down;
    font-size: rem(1);
    top: 50%;
    transform: translateY(-40%) scale(0.8) rotate(0deg); } }

.rollup__content--filters {
  font-size: rem(12); }
