.brand-subcategory {
  color: $white;
  padding: rem(18) 0;
  display: flex;
  flex-flow: row;
  &.categories__collapse {
    @include render-for(compact-scr) {
      border-width: 0;
      padding: 0; } }

  h5 {
    display: inline-block;
    margin: 0;
    font-weight: 600;
    @include render-for(compact-scr) {
      display: none; } }
  ul {
    margin: 0;
    display: inline-block;
    @include render-for(compact-scr) {
      display: block; } }
  li {
    display: inline-block;
    margin-left: rem(22);
    @include render-for(compact-scr) {
      display: list-item;
      margin-left: 0;
      text-align: center;
      padding: 0 rem(5);
      @include theme-color(border-color);
      overflow: hidden;
      border: 1px solid $gray-200;
      border-top: 0; } }
  a {
    color: $white;
    @include render-for(compact-scr) {
      color: $text-color;
      text-decoration: none;
      padding: rem(3) 0;
      margin: rem(10) 0;
      font-weight: 700;
      display: block; }
    &:hover {
      text-decoration: none;
      @include render-for(compact-scr) {
        @include theme-color(color); } } } }
