.thumbnails {
  display: flex;
  margin-top: rem(30);
  flex-wrap: wrap;

  li {
    flex: 1;
    padding: 0 rem(5);
    max-width: 25%;
    min-width: rem(85);
    margin-top: rem(10); } }

.thumbnail-item {
  display: flex;
  height: 100%;
  border: 1px solid $gray-400;
  align-items: center;
  justify-content: center;
  padding: rem(18) rem(15);
  text-decoration: none !important;

  img {
    min-width: rem(50); } }

.thumbnail-item__icon {
  height: 100%;
  padding: 0 rem(15);
  border-color: $primary;
  background-color: $primary;

  i {
    color: $white;
    font-size: rem(40);
    display: block;
    min-width: rem(50);
    text-align: center; } }
