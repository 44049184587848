@keyframes polygonOnLoad {
  0% {
    transform: skew(-30deg, 10deg) rotate(9deg);
    opacity: .3; }
  100% {
    transform: skew(-30deg, 10deg) rotate(0deg);
    opacity: 1; } }

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
