.switch {
  display: flex;
  margin-right: 0;
  position: relative;

  p {
    display: inline-block; }

  input[type="checkbox"] + label {
    padding-left: 0; }

  input[type="checkbox"] + label::after,
  input[type="checkbox"] + label::before {
    display: none; }

  input[type="checkbox"] {
    visibility: visible;
    min-width: auto; }

  input:checked + label .switch__on,
  label .switch__off {
    background-color: $white;
    border: none;
    width: rem(34);
    height: rem(34);
    margin: rem(2) rem(0);
    padding: rem(2);
    border-radius: rem(0);
    min-width: auto;
    color: $white;
    i {
      @include theme-color(color);
      padding: rem(5);
      margin: 0; } }

  label .switch__off {
    &:hover {
      @include theme-color(background-color); }
    i {
      padding: rem(6);
      &:hover {
        color: $white; } } }

  input:checked + label .switch__off,
  label .switch__on {
    @include theme-color(background-color);
    @include theme-color(border-color);
    width: rem(34);
    height: rem(34);
    margin: rem(2) rem(0);
    padding: rem(2);
    border-radius: rem(0);
    min-width: auto;
    i {
      color: $white;
      padding: rem(5);
      margin: 0; } }

  input + label .switch__on {
    &:hover {
      @include theme-color(background-color);
      @include theme-color(border-color);
      i {
        color: $white !important; } } }

  input:checked + label .switch__off {
    i {
      padding: rem(6); } } }

.form {
  .switch {
    .switch-wrapper {
      border: 1px solid $gray-400;
      display: inline-block;
      margin: 10px 8px;
      border-radius: 32px;
      .switch__on,
      .switch__off {
        width: rem(26);
        height: rem(26);
        margin: rem(2) rem(4);
        padding: 0;
        border-radius: rem(26); } }
    input:checked + label .switch__on,
    label .switch__off {
      background-color: transparent; } } }
