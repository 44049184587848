.notification {
  position: absolute;
  font-weight: 400;
  color: $white;
  display: block;
  font-size: rem(12);
  width: 17px;
  height: 17px;
  line-height: 17px;
  border-radius: 100%;
  left: 13px;
  top: -2px; }

.notification-black {
  background: $black; }

.notification--sub {
  top: auto;
  bottom: rem(5);
  left: auto;
  right: rem(-13);
  color: $black;
  font-weight: 700; }
