.product-overview {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-top: 1px solid $gray-200; }

.product {
  padding: 0 3% rem(21) 3.4%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-color: $gray-200;
  border-style: solid;
  @include render-to(xs) {
    padding-right: rem(15);
    padding-left: rem(15);
    width: 100%; } }

.product--2-cols {
  @include flex-width(50%);
  border-width: 0px 1px 1px 1px;
  @include render-to(md) {
    @include flex-width(100%); }
  &:nth-child(2n) {
    border-left-width: 0;
    @include render-to(md) {
      display: none; } }
  &:nth-child(-n+2) {
    border-top-width: 1px; } }

.product--3-cols {
  @include flex-width(33.333%);
  border-width: 0 1px 1px 0;
  @include render-up(map-get($grid-breakpoints, xl)) {
    &:nth-child(3n) {
      border-right-width: 0; } }
  @include render-to(lg) {
    @include flex-width(50%);
    &:nth-child(even) {
      border-right-width: 0; } }
  @include render-to(xs) {
    @include flex-width(100%);
    border-right-width: 0; } }

.product__desc {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 0 auto;

  h5 {
    margin: 0 0 rem(11);
    min-height: em(44, 19);
    font-size: rem(19);
    line-height: 1.16;

    a {
      text-decoration: none;
      color: inherit;
      @include render-to(lg) {
        font-size: rem(18); }
      &:hover {
        color: inherit;
        text-decoration: underline; } } }

  p {
    margin-bottom: rem(9);
    width: 100%;
    font-size: rem(15);
    order: -1;
    color: darken($gray-500, 5%);

    strong {
      color: $text-color; } }

  .special-prices {
    margin-bottom: rem(8);
    font-size: rem(15);
    line-height: 1.33;
    color: darken($gray-500, 5%);
    @include render-up(1581px) {
      margin-bottom: 0; }

    strong {
      padding-right: em(10, 15);
      min-width: em(131, 15);
      font-weight: 600;
      display: inline-block; } } }

.product__labels {
  position: absolute;
  left: 0;
  top: 0;
  display: flex; }

.product__labels--detail {
  position: static;
  margin-right: rem(15); }

.product__label {
  padding: em(7, 15) em(12, 15);
  font-size: rem(15);
  font-weight: 700;
  color: $white;
  background-color: $gray-600;
  border-color: $gray-600 transparent transparent transparent;
  @include render-to(sm) {
    font-size: rem(14); } }

.product__label + .product__label {
  margin-left: 1px; }

.product__label--blue {
  background-color: $label-blue;
  border-color: $label-blue transparent transparent transparent; }

.product__label--green {
  background-color: $label-green;
  border-color: $label-green transparent transparent transparent; }

.product__label--orange {
  background-color: $label-orange;
  border-color: transparent transparent $label-orange transparent; }

.product__info {
  padding: rem(5) rem(13) 0 0;
  position: absolute;
  top: 0;
  right: 0;

  .item {
    padding-left: rem(9);
    font-size: rem(21);
    text-decoration: none;
    color: $gray-icon; }

  .icon-scale {
    color: $gray-icon; }

  .icon-heart {
    color: inherit;
    &:hover {
      color: #fe254a; } }

  .icon-heart-fill {
    color: #fe254a; } }

.product__img {
  padding: rem(40) 0 rem(13);
  height: rem(239);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;

  img {
    max-width: 100%;
    max-height: 100%; } }

.product__logo {
  margin-bottom: rem(12);
  max-width: rem(120);
  height: rem(25);
  display: flex;
  align-items: flex-end;
  order: -1;

  img {
    max-width: 100%;
    max-height: rem(35); } }

.product__footer {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.product__footer--product-detail {
  align-items: center;
  position: relative;
  @include render-to(xs) {
    align-items: flex-end; }

  .product__buy {
    left: auto;
    margin-top: rem(20);

    .counter,
    .package-info {
      display: inline-block; }

    .package-info {
      font-size: rem(12);
      color: $gray-500;
      text-align: right;
      position: absolute;
      right: 100%;
      margin-right: rem(10);

      strong {
        display: block;
        font-size: rem(14); } } }

  .product__price {
    strong {
      font-size: rem(40);
      margin: rem(20) 0;
      display: block;
      @include render-to(xs) {
        margin: rem(5) 0; } }
    span.strong {
      font-size: rem(18);
      display: block;
      font-weight: 700; }

    .in-stock,
    .strong {
      @include render-to(xs) {
        position: absolute;
        left: 0; } }

    .strong {
      @include render-to(xs) {
        top: rem(30); } } } }

.product__price {
  font-size: rem(30);
  @include render-to(lg) {
    font-size: rem(27); } }

.product__buy {
  text-align: right;
  position: relative;
  left: 4%;
  @include render-to(xs) {
    left: 0; }

  .form-control-wrapper {
    vertical-align: top;
    display: inline-block; }

  .form-control {
    padding: rem(6) rem(20) rem(7) rem(10);
    width: rem(77);
    text-align: center;
    display: inline-block;
    color: $text-color;
    @include render-to(lg) {
      width: rem(65); } }

  .counter {
    display: none; }

  .btn {
    margin-left: rem(2);
    padding: rem(5) rem(14) rem(4);
    width: auto;
    vertical-align: middle;

    .icon {
      color: inherit; } }

  .btn-sm {
    padding: rem(9) rem(18); }

  p {
    margin: rem(8) 0 0;
    font-size: rem(15);
    display: block;
    color: darken($gray-500, 5%);
    @include render-to(lg) {
      font-size: rem(14); } } }

// variants
.product--detailed {
  .product__img {
    height: rem(190); }

  .product__desc {
    h5 {
      margin-bottom: rem(10); } } }

.product-overview--row {
  .product {
    @include flex-width(100%);
    padding: rem(12) rem(24) rem(12) rem(28);
    width: 100%;
    flex-direction: row;
    align-items: center;
    border-width: 1px;
    border-left: none;
    border-right: none;
    @include render-to(lg) {
      padding-left: rem(15);
      padding-right: rem(15); } }

  .product__info {
    padding: 0 rem(4) 0 0;
    width: rem(24);
    position: static;
    order: -1;

    .icon {
      padding: 0;
      display: none; }

    .icon-heart {
      display: inline-block; } }

  .product__img {
    padding: 0 rem(5) 0 rem(10);
    width: rem(60);
    height: rem(60);
    order: -1; }

  .product__desc {
    padding: 0 0 0 rem(12);
    width: auto;
    max-width: calc(100% - #{rem(577)});
    flex: 1 0 auto;
    @include render-to(lg) {
      max-width: calc(100% - #{rem(84)}); }

    h5 {
      margin: 0 rem(50) 0 0;
      min-height: 0;
      font-size: rem(17);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 400;
      @include render-to(lg) {
        margin-right: 0; }
      @include render-to(xs) {
        font-size: rem(16);
        white-space: normal; } }

    p {
      margin-bottom: rem(4);

      strong {
        font-weight: 600; } }

    .special-prices {
      display: none; } }

  .product__logo {
    display: none; }

  .product__labels {
    bottom: 0; }

  .product__label {
    padding: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 0;
    background: transparent;
    overflow: hidden; }

  .product__label + .product__label {
    position: absolute;
    bottom: 0;
    left: -1px;
    border-width: 0 16px 16px 0; }

  .product__footer {
    width: auto;
    justify-content: flex-start;
    @include render-to(lg) {
      margin-top: rem(10);
      padding-left: rem(96);
      width: 100%;
      justify-content: space-between; }
    @include render-to(sm) {
      padding-left: rem(30); }
    @include render-to(xs) {
      flex-wrap: wrap; } }

  .product__price {
    font-size: rem(19);
    display: flex;
    align-items: center;
    @include render-to(xs) {
      width: 100%;
      justify-content: space-between; }

    strong {
      width: rem(88);
      text-align: right;
      display: inline-block; }

    .in-stock {
      padding-right: rem(10);
      width: rem(130);
      order: -1; } }

  .product__buy {
    padding: 0 0 0 rem(10);
    width: rem(275);
    left: 0;
    display: flex;
    align-items: center;
    @include render-to(lg) {
      padding-left: 0;
      order: -1; }

    .counter {
      display: inline-block; }

    .btn {
      margin-left: rem(5); }

    p {
      margin: 0;
      padding-left: rem(19);
      width: rem(99);
      text-align: left; } } }

.product-overview--bordered {
  .product {
    border-left-width: 1px;
    border-right-width: 1px; } }

// in stock
.in-stock {
  font-size: rem(15);
  display: block;
  color: darken($gray-500, 5%);
  @include render-to(lg) {
    font-size: rem(14); }

  &::before {
    content: "";
    margin-right: em(5, 15);
    height: em(9, 15);
    width: em(9, 15);
    display: inline-block;
    border-radius: 50%;
    background: #78c45d; } }

.in-stock--empty {
  &::before {
    background: $red-dark; } }

.in-stock--up {
  &::before {
    background: #fdaa00; } }

.product--modal {
  .product__buy {
    left: auto; } }
