// lists
ul {
  margin: 0 0 1em;
  padding: 0;
  list-style: none; }

.list-gray {
  color: $gray-600; }

.list-mini {
  font-size: rem(16);
  line-height: rem(22); }

.list-full-width {
  width: 100%; }

.list-styled {
  li {
    margin: 0 0 rem(8);
    padding: 0 0 0 rem(21);
    position: relative;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      position: absolute;
      top: em(8);
      left: 0;
      border-radius: 50%;
      background: $primary; } } }

.list-styled--compact {
  li {
    margin-bottom: rem(1); } }

.list-links {
  margin-bottom: rem(30);
  a {
    font-size: rem(18);
    line-height: rem(32); } }

.list-basic {
  margin-bottom: rem(30);
  color: $gray-600;
  li {
    line-height: rem(27);
    padding-left: rem(17);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: rem(5);
      height: rem(5);
      left: 0;
      top: rem(10);
      background-color: $primary;
      border-radius: rem(5); } } }

.list-item--big {
  font-size: rem(22);
  color: $text-color;
  line-height: rem(32); }

.list-with-labels {
  @include render-to(xs) {
    margin-bottom: 0; }

  li {
    display: flex;
    @include render-to(xs) {
      margin-bottom: rem(10);
      flex-wrap: wrap; } }

  strong {
    flex: 1;
    max-width: rem(200);
    min-width: rem(200);
    @include render-to(xs) {
      max-width: none;
      width: 100%; } }

  span {
    color: $gray-600;
    @include render-to(xs) {
      width: 100%; } } }

.list-with-labels__content {
  flex: 1; }

.list-with-labels--full {
  strong {
    max-width: 30%;
    min-width: 30%; } }

.ordered-list__wrapper {
  ol {
    counter-reset: item;
    padding-left: 0;

    ol {
      margin: rem(30) 0; }

    h4 {
      line-height: rem(28); } }

  li {
    display: block;
    position: relative;

    &::before {
      opacity: 0;
      position: absolute;
      left: 0;
      content: counters(item, ".") ".";
      counter-increment: item;
      font-weight: bold;
      font-size: rem(16);
      color: $primary; }

    li {
      padding-left: rem(40); }

    li::before {
      opacity: 1; } } }

.list-item--action {
  position: relative;
  margin-left: rem(-20);
  a {
    position: absolute;
    top: 1px;
    right: rem(-25);
    margin-left: rem(-25);
    text-decoration: none;
    &:hover {
      text-decoration: none; } } }

.list-crossroads {
  font-size: rem(16);
  line-height: rem(34);
  a {
    color: $text-color;
    text-decoration: none;

    &:hover {
      color: $primary;
      text-decoration: underline; } } }

.list-squeezed {
  line-height: rem(24);
  a {
    margin: rem(2) 0;
    font-size: rem(14);
    display: inline-block; } }

.list-documents {
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    max-width: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
    width: 100%;
    margin: 0 rem(15);
    padding: rem(15);
    color: $gray-600;
    border-bottom: 1px solid $gray-200;
    @include render-to(sm) {
      max-width: 100%;
      flex-basis: 100%;
      margin: 0; }
    &:nth-last-child(-n+2) {
      border-bottom: none; }
    &:hover {
      background-color: $gray-100; } }

  a {
    text-decoration: none; } }

.documents__content {
  display: flex;
  align-items: center;
  padding-right: rem(20); }

.documents__name {
  display: inline-block;
  margin-left: rem(20);
  @include render-to(xs) {
    word-break: break-all; } }
