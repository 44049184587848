//
// Typography
// -------------------------------

// default
html {
  font-size: $font-size-base;
  @include render-to(sm) {
    font-size: $font-size-base - 1; } }

body {
  font-family: $font-family-sans-serif;
  letter-spacing: -0.04em; }

p {
  margin-bottom: rem(30);
  color: $gray-600;

  &.strong {
    color: $gray-800;
    font-weight: 600; }

  &.black {
    color: $text-color; } }

img {
  max-width: 100%; }

a {
  text-decoration: underline;
  color: $primary;
  @include hocus {
    color: darken($primary, 5%); } }

// headings
h1, .h1, {
  margin: rem(48) 0 rem(68);
  font-weight: 700;
  @include render-to(sm) {
    margin: rem(20) 0 rem(25);
    font-size: rem(29px); }

  strong {
    font-weight: 700; } }

h2, .h2 {
  font-size: rem(26);
  margin: 0 0 rem(50);
  font-weight: 700; }

h3, .h3 {
  font-weight: 700;
  line-height: 1.46;
  margin-bottom: rem(20);
  font-size: rem(24); }

h4, .h4 {
  margin: 0 0 1em;
  line-height: 1em;
  font-weight: 700;
  font-size: rem(22); }

h5, .h5 {
  margin: 0 0 1em;
  font-weight: 700;
  font-size: 1em;
  line-height: $line-height-base; }

.with-flag {
  position: relative;
  display: inline-block;
  margin-left: rem(70);
  margin-bottom: rem(40);

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: rem(-120);
    width: rem(100);
    top: 50%;
    height: 1px;
    @include theme-color(background-color); } }

hr {
  width: 100%;
  margin: rem(30) 0; }

.danger {
  color: $red; }

// note
.note {
  padding: rem(3) 0 0 rem(44);
  font-size: rem(16);
  position: relative;
  &::before {
    content: $icon-info;
    font-size: rem(30);
    line-height: 1;
    font-family: "icon-font";
    position: absolute;
    top: 0;
    left: 0;
    @include theme-color(color); } }
