@include render-for(compact-scr) {
  .filters-open {
    position: fixed;
    overflow: hidden;
    .sidebar-filters {
      &::before {
        z-index: 9998;
        opacity: 0.6; } }

    .sidebar-filters__content {
      left: 0; }

    .sidebar-filters__header {
      left: 0; } } }

.sidebar-filters {
  width: 100%;
  @include render-for(compact-scr) {
    &::before {
      content: "";
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #000000;
      opacity: 0;
      z-index: -9999;
      transition: opacity .2s ease-in-out; } } }

.sidebar-filters__content {
  padding: rem(28) rem(25) rem(28);
  @include render-for(compact-scr) {
    position: fixed;
    top: 0;
    left: rem(-250);
    bottom: 0;
    width: rem(250);
    z-index: 9999;
    padding-top: rem(97);
    background-color: #ffffff;
    overflow-y: scroll;
    transition: left .2s ease-in-out; } }

.sidebar-filters__header {
  display: none;
  @include render-for(compact-scr) {
    position: fixed;
    z-index: 2;
    top: 0;
    left: rem(-250);
    width: rem(250);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(25);
    border-bottom: 1px solid  #e9ecef;
    height: rem(69);
    background-color: #ffffff;
    transition: left .2s ease-in-out; } }

.sidebar-filters__heading {
  font-size: rem(25);
  font-weight: 700; }

.sidebar-filters__link {
  @include theme-color(color);
  font-size: rem(15);
  cursor: pointer;
  .icon {
    display: inline-block;
    margin-right: rem(5);
    font-size: rem(10); }

  span {
    text-decoration: underline; } }

.sidebar-filter {
  @include render-for(compact-scr) {
    position: relative;
    z-index: 1; } }

.sidebar-filter__content {
  &.show {
    .sidebar-filter__filters::after {
      border-color: #e9ecef; } } }

.sidebar-filter__filters {
  position: relative;
  padding-top: rem(5);
  padding-bottom: rem(50);
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(25);
    display: block;
    border-bottom: 1px solid transparent; }

  &.show::after {
    border-color: #e9ecef; }

  label {
    margin-bottom: rem(8);
    font-size: rem(15); }

  .badge {
    margin-left: rem(8); } }

.sidebar-filters__toggle {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: rem(10) rem(27) rem(10) 0;
  font-size: rem(11);
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  background: transparent;
  letter-spacing: em(1.54);
  cursor: pointer;
  color: $text-color;
  &:focus {
    outline: 0; }

  &[aria-expanded="true"] {
    .icon {
      transform: translateY(-50%) rotate(-180deg); } }

  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    color: #bdbdbd;
    font-size: rem(10);
    transition: transform .2s ease-in-out; } }
