//
// Variables
// -------------------------------

// Colors
$white:                   #ffffff;
$black:                   #000000;
$gray-form-border:        #e5e5e5;
$gray-icon:               #6c757d;
$brown-hover:             #732e00;

$billboard-vulcan:        #19171c;
$billboard-orange_white:  #e8dfd2;

$red:                     #fa0a00;
$red-dark:                #D60000;

$green:                   #5cb53f;

$primary:                 #ff7900;

$text-color:              #10252f;

$icon-red-color:          #d00c0c;
$icon-green-color:        #35920b;
$icon-orange-color:       $primary;

$label-green:             #89d574;
$label-blue:              #6ec6dc;
$label-orange:            #ff7900;

$brand-red:               #e32823;
$brand-carmine:           #ef3e42;
$brand-black:             #000000;
$brand-yellow:            #f1c13c;
$brand-dark:              #1d1d1b;
$brand-red_dark:          #e30b20;
$brand-broom:             #EECA3B;
$brand-blue:              #0B4377;
$brand-golden_dark:       #F9B004;
$brand-carrot_orange:     #F59331;
$brand-green_atlantis:    #92C24A;
$brand-blue_cerulean:     #00A4D8;
$brand-blue_endevour:     #0A549D;

// Typography
$font-family-sans-serif:  'Exo 2', sans-serif;
$font-family-base:        $font-family-sans-serif;
$line-height-base:        1.5;
$font-size-base:          18px;

$spacer:                  0.88rem;
$sidebar-padding:         1.5rem;

$heading-color-primary:   $gray-800;
$heading-color-secondary: $gray-700;

// Grid system
$grid-gutter-width:       24px;
$grid-gutter-width-lg:    144px;
$grid-gutter-width-md:    106px;
$grid-gutter-width-sm:    60px;
$grid-gutter-width-ms:    30px;
$grid-gutter-width-xs:    12px;

// Container sizes
$container-lg:            (1110px + $grid-gutter-width);
$container-xlg:           (1620px + $grid-gutter-width);

//== Media queries breakpoints
$screen-nd-min:           1025px;
$screen-nd-max:           1649px;
$screen-xlg:              1921px;
$screen-xlg-min:          $screen-xlg !default;

$navbar-breakpoint:                    992px;
$compact-breakpoint:                   1200px;
$navbar-eshop-breakpoint-md:           1040px;
$navbar-eshop-breakpoint-lg:           1380px;

//== Pagination
$pagination-color:                     $gray-400;
$pagination-bg:                        #fff;
$pagination-border:                    $gray-form-border;

$pagination-hover-color:               #fff;
$pagination-hover-bg:                  $red-dark;
$pagination-hover-border:              $gray-form-border;

$pagination-active-color:              $gray-800;
$pagination-active-bg:                 #fff;
$pagination-active-border:             $gray-form-border;

$pagination-disabled-color:            $gray-400;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           $gray-form-border;
