.tabs-wrapper {
  li {
    flex: 1 auto;
    display: flex;
    @include render-to(xs) {
      width: 100%; } } }

.tabs-gray {
  .tab.active {
    background: $gray-100;
    border-bottom: 1px solid $gray-100; }

  .tab.white.active {
    background-color: $white;
    border-bottom: 0; } }


.tabs-small {
  .tab {
    padding: rem(10) 0;
    &.active {
      padding-top: rem(9); } } }

.tabs-black {
  .tab {
    color: $text-color; } }

.tab {
  display: inline-block;
  text-align: center;
  flex: 1 auto;
  text-decoration: none;
  font-weight: bold;
  color: $gray-400;
  padding: rem(20) 0;
  border-bottom: 1px solid $gray-400;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 2px solid transparent;
  @include render-to(xs) {
    width: 100%; }
  &:not([href]):not([tabindex]) {
    color: $primary;
    &:hover {
      color: $primary; } }
  &:hover {
    text-decoration: none;

    .tab__order {
      background-color: $primary;
      color: $white;
      border-color: $primary; } }

  &.active {
    color: $primary;
    padding-top: rem(19);
    border-bottom: none;
    border-left-color: $gray-400;
    border-right-color: $gray-400;
    border-top-color: $primary;
    @include render-to(xs) {
      border-right: none;
      border-left: none;
      border-top: none;
      border-bottom: 1px solid $primary; }

    .tab__order {
      background-color: $primary;
      color: $white;
      border-color: $primary; } } }

.tab__order {
  background-color: transparent;
  border-radius: 100%;
  display: inline-block;
  line-height: rem(20);
  font-size: rem(16);
  width: rem(25);
  height: rem(25);
  color: $gray-400;
  border: 1px solid $gray-400;
  text-align: center;
  font-weight: normal;
  margin-right: rem(15); }
