.breadcrumb {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  margin-bottom: 0; }

.breadcrumb__item {
  text-decoration: none;
  font-size: rem(14);
  @include theme-color(color);

  &:first-child:hover {
    text-decoration: none; }

  &:not([href]) {
    color: $gray-600 !important;
    @include hocus {
      color: $gray-600; } }

  & + .breadcrumb__item::before {
    content: ">";
    font-size: 20px;
    line-height: 0;
    color: $gray-400;
    transform: scale(.5,1.5);
    margin-left: 3px;
    display: inline-block;
    padding: 0 rem(10); } }
