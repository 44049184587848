//
// Forms
// -------------------------------

.control-label {
  color: lighten($gray-400, 3%);

  abbr {
    display: none; } }

form {
  hr {
    margin-top: rem(50);
    @include render-to(md) {
      display: none; } } }

.form-group {
  margin-bottom: 0; }

.form-group--inline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-top: rem(20);
  max-width: rem(550);
  position: relative;
  @include render-to(xs) {
    flex-direction: column; }

  input {
    margin-right: rem(10);
    width: 100%;
    flex: 5; }

  select,
  .select-wrapper {
    width: 100%;
    flex: 5; }

  label,
  h6 {
    font-size: rem(16);
    line-height: rem(34);
    font-weight: bold;
    margin-right: rem(15);
    flex: 1; }

  .label-row-big {
    margin-right: 0;
    margin-bottom: 0;
    padding-top: rem(3);
    min-width: rem(150);
    max-width: rem(150); }

  .label-row-small {
    min-width: rem(70);
    margin-right: 0; }

  & + .select-content {
    padding-left: rem(150);
    @include render-to(xs) {
      padding-left: 0; }

    strong {
      margin-right: rem(10); } }

  .inline-paragraph {
    font-size: rem(16);
    line-height: rem(34); }

  .label-checkbox {
    margin-bottom: 0;
    margin-top: rem(6);
    line-height: rem(22); }

  .invalid-feedback {
    width: 100%;
    padding-left: rem(150);
    @include render-to(xs) {
      padding-left: 0; } } }

.form-group--inline-indent-big {
  padding-left: rem(150);
  @include render-to(xs) {
    padding-left: 0; } }

.form-group--full-width {
  max-width: none;

  .form-group {
    width: 100%; } }

.form-control {
  font-size: rem(14);
  padding: rem(9) rem(13);
  border-radius: 0;
  &:focus {
    border-color: darken($input-border-color, 12%);
    box-shadow: none; } }

.form-control--sm {
  padding-top: rem(7);
  padding-bottom: rem(7); }

textarea {
  min-height: rem(125px); }

label {
  font-weight: 700;
  margin-right: rem(20);
  &.small {
    .switch__on,
    .switch__off {
      margin: rem(10) 0; } }
  &.gray {
    color: $gray-600; } }

.hidden-input {
  opacity: 0;
  position: absolute;
  visibility: hidden; }

input[type="file"] {
  @extend .hidden-input;
  width: 0; }

input[type="radio"] {
  & + label::before,
  & + label::after {
    border-radius: 100%; } }

input[type="checkbox"],
input[type="radio"] {
  @extend .hidden-input;
  width: auto;
  min-width: auto !important;

  & + label {
    font-weight: normal;
    position: relative;
    padding-left: rem(27);
    align-items: flex-start;
    margin-right: 0; }

  & + label::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $gray-400;
    background-color: $white; }

  & + label::after {
    content: "";
    position: absolute;
    display: inline-block;
    left: 4px;
    top: 6px;
    width: 10px;
    height: 10px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.25s;
    @include theme-color(background-color); }

  &:checked + label::after {
    opacity: 1; } }

.disable {
  label {
    color: $gray-400; } }

input {
  &.small {
    max-width: rem(150); } }

select {
  background-color: transparent;
  border-color: $gray-400;
  z-index: 9;
  &::-ms-expand {
    display: none; }
  &.form-control {
    appearance: none;
    border-radius: 0;
    color: $text-color;
    font-size: rem(16);
    min-width: auto !important;
    height: auto !important;
    padding-right: rem(27);
    line-height: rem(21);
    background-color: transparent; }
  &.big {
    min-width: rem(150) !important; }
  &.large {
    min-width: rem(220) !important; } }

.help-block {
  padding: rem(4px) 0 0;
  font-size: rem(12px); }

.has-error {
  .form-control {
    border-color: map-get($theme-colors, danger); }

  .help-block {
    color: map-get($theme-colors, danger); } }

@include render-up(lg) {
  .form-inline {
    .form-control {
      min-width: rem(190); } } }

.form-control-wrapper {
  position: relative;
  &.required::after {
    content: "*";
    position: absolute;
    top: 0;
    right: rem(-15);
    color: $gray-700; }

  .units {
    padding: rem(9) rem(7) rem(9) 0;
    font-size: rem(15);
    line-height: 1.25;
    display: block;
    @include all-abs;
    left: auto;
    color: darken($gray-500, 5%); } }

.form-multi-row {
  label {
    min-width: rem(170);
    justify-content: left; }

  .form-group {
    margin: rem(25) 0;
    align-items: baseline;
    &:last-child {
      margin-bottom: 0; }
    @include render-to(md) {
      margin: rem(15) 0; } }

  .form-control {
    @include acus {
      border-color: $gray-600; }
    @include render-to(lg) {
      width: rem(300); }
    @include render-to(xs) {
      width: 100%; } }

  .float-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;

    .form-group {
      margin: 0;
      position: relative; }

    @include render-to(xs) {
      flex-direction: column;
      .form-group {
        margin-bottom: rem(10); } } } }

.form-group-search-holder {
  display: inline-block;
  position: relative;
  &::after {
    content: $icon-search;
    font-family: "icon-font";
    position: absolute;
    top: 50%;
    right: rem(12);
    transform: translateY(-50%);
    @include theme-color(color); }

  .form-control {
    width: 100%;
    display: block; }

  @include render-to(xs) {
    margin-bottom: rem(10);
    width: 100%; } }

// adaptive placeholder
.adaptive-placeholder {
  &.form-group {
    margin: 0 0 rem(12);
    position: relative; }

  .control-label {
    margin: 0;
    padding: rem(1) rem(5);
    position: absolute;
    top: rem(13px);
    left: rem(12px);
    transform: translate(0, 0);
    transition: all 0.3s ease 0s; }

  .form-control {
    &:focus,
    &.not-empty {
      & + .control-label {
        font-size: rem(12);
        transform: translate(2px, rem(-22));
        background-color: white; } } } }

// custom file input
.form-group.file {
  .control-label {
    @extend .form-control;
    padding-right: rem(160);
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    color: $input-placeholder-color;
    overflow: hidden;
    cursor: pointer;

    &::after {
      content: attr(title);
      padding: 0 rem(22);
      font-weight: 700;
      font-size: rem(14);
      display: flex;
      align-items: center;
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      background-color: $blue;
      color: white; } }

  input[type="file"] {
    display: none; } }

.form-group--checkbox.hidden {
  display: none !important; }
