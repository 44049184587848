.box-gray {
  width: 100%;
  padding: rem(45) rem(50);
  margin-bottom: rem(20);
  background-color: $gray-100;
  @include render-to(md) {
    padding: rem(30); } }

.box-gray--mini {
  padding: rem(30) rem(35);
  text-align: center;

  p {
    max-width: 100%; } }

.box-gray--tiny {
  padding: rem(15) rem(25) 0; }

.box-flex {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .box-flex {
    width: auto; } }

.box-flex--start {
  justify-content: flex-start; }

.box-flex--end {
  justify-content: flex-end; }

.box-flex--column {
  flex-direction: column;
  justify-content: space-between; }

.box-flex--column-sm {
  @include render-to(sm) {
    flex-direction: column;
    &.box-flex--with-icon {
      i {
       margin: 0; } } } }

.box-flex--column-xs {
  @include render-to(xs) {
    flex-direction: column; } }

.box-flex--with-icon {
  align-items: flex-start;
  justify-content: flex-start;
  .icon-small {
    margin-right: rem(20); }
  .icon-medium {
    margin-right: rem(30); }
  .icon-large {
    margin-right: rem(80); } }

.box-flex--with-icon-center {
  align-items: center; }
