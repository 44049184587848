.polygon {
  position: relative;

  &::before {
    background-color: #f3f5f6;
    display: block;
    content: " ";
    width: 100%;
    height: 800px;
    left: -47%;
    bottom: rem(-40);
    position: absolute;
    z-index: -10;
    transform-origin: 100%;
    transform: skew(-30deg, 10deg) rotate(0deg);
    animation: 2s cubic-bezier(0, 0, 0, 1) polygonOnLoad;
    @include render-to(sm) {
      left: rem(-15);
      right: rem(-15);
      width: auto;
      height: rem(500);
      top: rem(-990);
      transform: skew(0deg, -4deg) rotate(0deg);
      animation: none; } } }
